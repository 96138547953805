import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Alert, Col, Row, Form } from 'react-bootstrap';
import { Loader } from '../../../ui/Loader';
import { UnificadorTableMock } from './UnificadorTableMock';
import { ScreenWithFabButtonsCopy } from '../../../ui/ScreenWithFabButtonsCopy';
import { routePaths } from '../../../utils/Constants';

const mockData = {
    titulos: ["Título 1", "Título 2", "Título 3"],
    procedencias: ["Procedencia 1", "Procedencia 2", "Procedencia 3"],
    resoluciones: ["Resolución 1", "Resolución 2", "Resolución 3"],
};

export const TernasUnificadasSearch = () => {
    const [allElementos, setAllElementos] = useState([]);
    const [elementos, setElementos] = useState([]);
    const [page, setPage] = useState(1);
    const [cantidad, setCantidad] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [filters, setFilters] = useState({
        titulo: '',
        procedencia: '',
        resolucion: '',
    });

    const dispatch = useDispatch();

    const searchUnificaciones = async () => {
        try {
            setIsLoading(true);
            const response = {
                page: 1,
                cantidad: 5,
                elementos: [
                    { id: 1, terna: { titulo: { nombre: "Título 1" }, procedencia: { nombre: "Procedencia 1" }, resolucion: { nombre: "Resolución 1" }, estado: { nombre: "Aprobado" } } },
                    { id: 2, terna: { titulo: { nombre: "Título 2" }, procedencia: { nombre: "Procedencia 2" }, resolucion: { nombre: "Resolución 2" }, estado: { nombre: "Aprobado" } } },
                    { id: 3, terna: { titulo: { nombre: "Título 3" }, procedencia: { nombre: "Procedencia 3" }, resolucion: { nombre: "Resolución 3" }, estado: { nombre: "Aprobado" } } },
                ],
            };

            setAllElementos(response.elementos);
            setElementos(response.elementos);
            setPage(response.page);
            setCantidad(response.cantidad);
        } catch (err) {
            dispatch({ type: 'error', payload: "No se pudieron cargar las unificaciones" });
        } finally {
            setIsLoading(false);
        }
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    const applyFilters = () => {
        const filtered = allElementos.filter((el) => {
            return (
                (!filters.titulo || el.terna.titulo.nombre.includes(filters.titulo)) &&
                (!filters.procedencia || el.terna.procedencia.nombre.includes(filters.procedencia)) &&
                (!filters.resolucion || el.terna.resolucion.nombre.includes(filters.resolucion))
            );
        });

        setElementos(filtered);
        setCantidad(filtered.length);
    };

    const resetFilters = () => {
        setFilters({ titulo: '', procedencia: '', resolucion: '' });
        setElementos(allElementos);
        setCantidad(allElementos.length);
    };

    useEffect(() => {
        searchUnificaciones();
    }, []);

    return (
        <ScreenWithFabButtonsCopy
            hidePlusButton={false}
            hideSearchButton={false}
            hideCleanButton={false}
            searchButtonDisabled={isLoading}
            cleanButtonDisabled={isLoading || (!filters.titulo && !filters.procedencia && !filters.resolucion)}
            onSearch={applyFilters}
            onClean={resetFilters}
            plusPath={routePaths.ABM_INCUMBENCIAS_UNIFICADOR_CREATE}
            returnPath={routePaths.HOME_TERNAS}
        >
            <Row>
                <Col>
                    <h1>Ternas Unificadas</h1>
                    <Form className="mb-3">
                        <Row>
                            <Col sm={12} md={4}>
                                <Form.Group controlId="filterTitulo">
                                    <Form.Label>Nombre de título</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="titulo"
                                        value={filters.titulo}
                                        onChange={handleFilterChange}
                                    >
                                        <option value="">Seleccionar título</option>
                                        {mockData.titulos.map((titulo, index) => (
                                            <option key={index} value={titulo}>{titulo}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={4}>
                                <Form.Group controlId="filterProcedencia">
                                    <Form.Label>Procedencia</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="procedencia"
                                        value={filters.procedencia}
                                        onChange={handleFilterChange}
                                    >
                                        <option value="">Seleccionar procedencia</option>
                                        {mockData.procedencias.map((procedencia, index) => (
                                            <option key={index} value={procedencia}>{procedencia}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={4}>
                                <Form.Group controlId="filterResolucion">
                                    <Form.Label>Resolución</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="resolucion"
                                        value={filters.resolucion}
                                        onChange={handleFilterChange}
                                    >
                                        <option value="">Seleccionar resolución</option>
                                        {mockData.resoluciones.map((resolucion, index) => (
                                            <option key={index} value={resolucion}>{resolucion}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>

                    {elementos.length > 0 ? (
                        <UnificadorTableMock elementos={elementos} cantidad={cantidad} page={page} />
                    ) : (
                        !isLoading && (
                            <Row className="form-group">
                                <Col>
                                    <Alert variant="info">No se encontraron unificaciones.</Alert>
                                </Col>
                            </Row>
                        )
                    )}
                    {isLoading && <Loader />}
                </Col>
            </Row>
        </ScreenWithFabButtonsCopy>
    );
};
