import React from 'react'
import { IncumbenciasABMTable } from '../../abm/IncumbenciasABMTable'
import { incumbenciasConstants } from '../../../utils/Constants'

export const ClonadorCargoTable = ({
    elementos,
    handleClickFinalizarCargaFunction,
    tipoDocumento,
    renderSelect
}) => {
    const tipo = incumbenciasConstants.CARGOS_INCUMBENCIA

  return <IncumbenciasABMTable
    elementos={elementos}
    tipo={tipo}
    renderTableRows={() => renderSelect(
      elementos,
      tipo,
      (variante) => handleClickFinalizarCargaFunction(variante, tipoDocumento))
    }
    showCheckboxCol={false}
    showCondicionCol={false}
    showFolioCol={false}
    showActionsCol={true}
    showApendiceCol={false}
    showPaginacion={false}
    showCantidadIncumbencias={true}
    hideTipoEscuela={ elementos[0].type === "simple" }
  // showApendiceCol={false}
  />
}
