import React, { useEffect, useRef, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import Input from '../../ui/Input'
import * as especialidadesService from "../../especialidades/EspecialidadesService";
import { handlerChange, removeUndefinedKeys } from '../../utils/commons';
import { useDispatch, useSelector } from 'react-redux';
import { cleanFab, searchFab } from '../../store/screen/screenActions';
import { clear } from '../../store/alerts/alertActions';
import { getEscuelasByVariantes } from '../../escuela/EscuelaService';
import CheckBoxEstados from '../../generics/CheckBoxEstados';

export const FormSearchCruzadosArea = ({
	inputsForm,
	setInputsForm,
	searchCruzadosArea,
	resetForm,
	resetChecks,
	setResetChecks
}) => {
	const [opcionesAreas, setOpcionesAreas] = useState()
	const [opcionesEscuelas, setOpcionesEscuelas] = useState();
	const [areaSeleccionada, setAreaSeleccionada] = useState("");
	const [tipoEscuelaSeleccionada, setTipoEscuelaSeleccionada] = useState("");
	const [resetInputsForm, setResetInputsForm] = useState(0)

	const [isRefreshing, setIsRefreshing] = useState(false)
	const isRefreshRef = useRef(false)

	const filtrosDefault = {}

	const screenState = useSelector(st => st.screen);
	const dispatch = useDispatch();

	const isMountedRef = useRef(true);

	const resetStates = () => {
		setResetInputsForm(st => st + 1)
		setOpcionesAreas(null)
		setOpcionesEscuelas(null)
		setAreaSeleccionada(null);
		setTipoEscuelaSeleccionada(null);
	}

	const handleChange = (e) => {
		if (e.target.id == "area_id") setOpcionesAreas([])
		if (e.target.id == "tipo_escuela_id") setOpcionesEscuelas([])
		handlerChange(setInputsForm, inputsForm, null, e)
		refreshParentsInputs({ [e.target.id]: e.target.value }, e.target.isBusquedaAvanzada)
	};

	const processSubmit = () => {
		searchCruzadosArea(inputsForm, isMountedRef);
	}

	const refreshParentsInputs = async (initialFilters = {}, esBusquedaAvanzada) => {
		if (areaSeleccionada?.value == undefined || tipoEscuelaSeleccionada?.value == undefined) {
			if (!isRefreshing && isRefreshRef.current == false) {
				setIsRefreshing(true)
				isRefreshRef.current = true
				const arrRefreshCallBacks = []

				const initialKey = Object.keys(initialFilters).length > 0 ? Object.keys(initialFilters)[0] : undefined

				let refreshedParams = initialFilters;
				if (esBusquedaAvanzada) refreshedParams[initialKey + "_busquedaAvanzada"] = true

				const nextCb = async (index) => {
					if (arrRefreshCallBacks.length >= index) await arrRefreshCallBacks[index + 1](index + 1)
				}

				const listRefresh = [
					{
						isValidRefresh: areaSeleccionada?.value == undefined,
						endpoint: especialidadesService.getAreasByVariantes,
						response_elements_key: "data",
						param_key: "area",
						setterValue: setAreaSeleccionada,
						setterOpciones: setOpcionesAreas,
					},
					{
						isValidRefresh: tipoEscuelaSeleccionada?.value == undefined,
						endpoint: getEscuelasByVariantes,
						response_elements_key: "data",
						param_key: "tipo_escuela",
						setterValue: setTipoEscuelaSeleccionada,
						setterOpciones: setOpcionesEscuelas,
					},
				]

				listRefresh.forEach(ctRefresh => {
					if (ctRefresh.isValidRefresh && initialKey != ctRefresh.param_key) {
						const refreshFunc = async (ctIndex) => {

							console.log("ACAA", {
								inputsForm,
								areaSeleccionada,
								tipoEscuelaSeleccionada,
								refreshedParams,
							})

							const { cantidad, [ctRefresh.response_elements_key]: elementos } = await ctRefresh.endpoint(removeUndefinedKeys({ ...filtrosDefault, ...refreshedParams }));
							if (cantidad == 1) {
								ctRefresh.setterValue({ value: elementos[0].id, label: elementos[0].nombre })
								setInputsForm(st => ({ ...st, [ctRefresh.param_key]: elementos[0].id }))
								refreshedParams = { ...refreshedParams, [ctRefresh.param_key]: elementos[0].id }
							}
							if (cantidad <= window.Configs.MAX_OPTIONS_FOR_SIMPLE_SELECTS) ctRefresh.setterOpciones(elementos.map(ctOp => ({ value: ctOp.id, label: ctOp.nombre })))
							await nextCb(ctIndex)
						}
						arrRefreshCallBacks.push(refreshFunc)
					}
				})

				if (arrRefreshCallBacks.length > 0) {
					const finishRefresh = () => {
						setIsRefreshing(false)
						isRefreshRef.current = false
					}
					arrRefreshCallBacks.push(finishRefresh)
					await arrRefreshCallBacks[0](0)
				} else {
					setIsRefreshing(false)
					isRefreshRef.current = false
				}
			}
		}
	}

	useEffect(() => {
		isMountedRef.current = true;
		dispatch(clear());
		return () => (isMountedRef.current = false);
	}, [dispatch]);

	useEffect(() => {
		if (screenState.clean) {
			resetForm()
			resetStates()
			dispatch(cleanFab(false));
		}

		if (screenState.search) {
			processSubmit();
			dispatch(searchFab(false));
		}
	}, [screenState])

	return (
		<Form
			onSubmit={(e) => e.preventDefault()}
			className="w-100"
		>
			<Row key={resetInputsForm}>
				<Col Col sm={12} md={6} lg={4}>
					<Input
						type="react-select"
						controlId="area"
						name="area"
						label="Area"
						value={areaSeleccionada}
						methodOnChange={handleChange}
						elements={especialidadesService.getAreasByVariantes}
						nameElementsResponse="data"
						propertiesForFormat={{ value: "id", label: "nombre" }}
						setterElementSelect={setAreaSeleccionada}
						config={{ isSearchable: true, withAsyncDelay: true }}
						paramsFilter={filtrosDefault}
						syncElements={opcionesAreas}
						isLoading={isRefreshing}
						isDisabled={isRefreshing}
						noCleanInNullRelated={true}
						preloadRelatedFilters={true}
						countCaracters={0}
						withBusquedaAvanzada={true}
					></Input>
				</Col>
				<Col Col sm={12} md={6} lg={4}>
					<Input
						type="react-select"
						controlId="tipo_escuela"
						name="tipo_escuela"
						label="Tipo Escuela"
						value={tipoEscuelaSeleccionada}
						methodOnChange={handleChange}
						elements={getEscuelasByVariantes}
						nameElementsResponse="data"
						propertiesForFormat={{ value: "id", label: "nombre" }}
						setterElementSelect={setTipoEscuelaSeleccionada}
						config={{ isSearchable: true, withAsyncDelay: true }}
						paramsFilter={filtrosDefault}
						syncElements={opcionesEscuelas}
						isLoading={isRefreshing}
						isDisabled={isRefreshing}
						noCleanInNullRelated={true}
						preloadRelatedFilters={true}
						countCaracters={0}
					></Input>
				</Col>
			</Row>
			<Row className="form-group">
				<Col sm={12}>
					<CheckBoxEstados
						inputsForm={inputsForm}
						setInputsForm={setInputsForm}
						resetChecks={resetChecks}
						setResetChecks={setResetChecks}
					/>
				</Col>
			</Row>
		</Form >
	)
}
