import { faTrash } from '@fortawesome/free-solid-svg-icons';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { Button, Col, Modal, Row, Table } from 'react-bootstrap';
import Dialog from '../../generics/dialog';
import Paginacion from '../../generics/Paginacion';
import { CustomTooltip } from '../../ui/CustomTooltip';
import Input from '../../ui/Input';
import { Loader } from '../../ui/Loader';
import { agregarDescargas, OPCIONES_DESCARGAS } from '../../store/descargas/descargasActions.';
import { error } from '../../store/alerts/alertActions';
import { useDispatch, useSelector } from 'react-redux';

export const RelacionadosRow = ({
    relacionId,
    terna,
    checkState,
    onChangeCheckState,
    onClickDelete
}) => {

    return (
        <tr>
            <td>{terna.titulo.nombre}</td>
            <td>{terna.procedencia.nombre}</td>
            <td>{terna.resolucion.nombre}</td>
            <td className="d-flex justify-content-center">
                {onChangeCheckState ?
                    <Input
                        className="checkboxEstados d-flex justify-content-center"
                        type="checkbox"
                        controlId={relacionId}
                        onChange={onChangeCheckState}
                        checked={checkState}
                    ></Input>
                    :
                    <CustomTooltip text={"Remover de la lista"}>
                        <Dialog
                            action={onClickDelete}
                            target={relacionId}
                            title={"Remover titulo"}
                            body={<span>¿Estás seguro que quiere remover el titulo del informe?</span>}
                            size="md"
                            btnIcon={faTrash}
                            btnVariant={"danger"}
                            variant={"danger"}
                            btnConfirmText="Eliminar"
                        />
                    </CustomTooltip>
                }
            </td>
        </tr>
    )
}


export const RelacionadosTable = ({
    children,
    hideAddToInforme,
    cantidadElementos,
    page,
    handleChangePage,
    hidePagination,
}) => {
    return (<>
        <Row className="form-group">
            <Col className="w-100">
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Nombre del titulo</th>
                            <th>Procedencia</th>
                            <th>Resolución</th>
                            <th style={{ width: "1%" }}> {!hideAddToInforme && "Agregar al reporte"}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {children}
                    </tbody>
                </Table>
            </Col>
        </Row>
        {!hidePagination &&
            <Row>
                <Paginacion totalItems={cantidadElementos} currentPage={Number(page)} onChangePage={handleChangePage} filasPorPaginas={5} />
            </Row>}
    </>)
}

export const RelacionadosInfoModal = ({
    ternaId,
    selectedRelacionados,
    cantidadRelacionados,
    arrRelacionados,
    page,
    changeSelected,
    loadData,
    nombreRelacion,

}) => {
    const [newSelectedState, setNewSelectedState] = useState(selectedRelacionados);
    const [searching, setSearching] = useState(false);
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();

    const onChangeCheckState = (event, selectedRelacion) => {
        setNewSelectedState(st => {
            let newSt = [...st]
            if (event.target.checked) {
                newSt = [...newSt, selectedRelacion];
            } else {
                newSt = newSt.filter(ctRelacion => ctRelacion.id != selectedRelacion.id)
            }
            return newSt
        })
    }

    const handleChangePage = async (event, value) => {
        try {
            setSearching(true)
            await loadData(value)
        } catch (err) { }
        finally { setSearching(false) }
    }

    const handleClose = () => {
        setShow(false);
        setNewSelectedState(selectedRelacionados);
    };

    const handleShow = () => {
        setShow(true)
    };

    const handleClickApply = () => {
        changeSelected(newSelectedState)
        handleClose();
    }

    useEffect(() => {
        setNewSelectedState(selectedRelacionados)
    }, [selectedRelacionados])

    const handleClickGenerarInforme = async () => {
        try {
            let filtros = {
                terna_id: ternaId,
                tipo_informe: arrRelacionados[0]?.tipoInforme 
            }

            const fileName = "Informe Equilencias por Titulos"
            handleClose()
            dispatch(agregarDescargas([{
                title: "Informe / Informe Equilencias por Titulos",
                opcion: OPCIONES_DESCARGAS.PDF.INFORME_EQUIVALENCIAS,
                filtros: filtros,
                descripcion: "TODO: ACA IRIA UNA DESCRIPCION DEL ARCHIVO QUE SE ESTA DESCARGANDO.",
                fileName,
            }]));
        } catch (err) {
            handleClose()
            dispatch(error(err.message))
        }
    }

    return (
        <div className="d-flex align-items-center">
            {cantidadRelacionados >= 0 && (cantidadRelacionados > 0 ?
                <>
                    <button type="button" className="btn btn-link align-self-start" onClick={handleShow}>
                        El titulo presenta {cantidadRelacionados} {nombreRelacion}{cantidadRelacionados == 1 ? "" : "s"}.
                    </button>
                    <Modal
                        onHide={handleClose}
                        size="xl"
                        aria-labelledby="contained-modal-title-vcenter"
                        show={show}
                        backdrop={true}
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <span className="font-weight-light">Relaciones {nombreRelacion}s:</span>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {!searching &&
                                <RelacionadosTable
                                    cantidadElementos={cantidadRelacionados}
                                    page={page}
                                    handleChangePage={handleChangePage}
                                >
                                    {arrRelacionados.map(ctRelacionRow => {
                                        return (
                                            <React.Fragment key={"RelacionadoRow-" + nombreRelacion + "-" + ctRelacionRow.id}>
                                                <RelacionadosRow
                                                    relacionId={ctRelacionRow.id}
                                                    terna={ctRelacionRow.terna}
                                                    checkState={newSelectedState.find(ctRelSelected => ctRelSelected.id == ctRelacionRow.id)}
                                                    onChangeCheckState={ev => onChangeCheckState(ev, ctRelacionRow)}
                                                />
                                            </React.Fragment>
                                        )
                                    })}
                                </RelacionadosTable>
                            }

                            {searching && <Loader></Loader>}

                        </Modal.Body>
                        <Modal.Footer>
                            <Row className="w-100 justify-content-between" xs="auto">
                                <Col xs="auto">
                                    <Dialog
                                        action={handleClickGenerarInforme}
                                        title={`Informe ${nombreRelacion}${cantidadRelacionados == 1 ? "" : "s"}` }
                                        variant="primary"
                                        size="md"
                                        btnConfirmText="Generar"
                                        btnVariant="primary"
                                        btnText="Informe Equivalencias"
                                        isLoadingText={"Generando informe"}
                                        body={<span>
                                            ¿Estás seguro de generar el informe?
                                        </span>}
                                    />
                                </Col>

                                <Col xs="auto">
                                    <Button variant="secondary" onClick={handleClose}>
                                        Volver
                                    </Button>
                                    <Button variant="primary" onClick={handleClickApply}>
                                        Aplicar
                                    </Button>
                                </Col>
                            </Row>

                        </Modal.Footer>
                    </Modal>
                </>
                :
                <p className="my-0"> El titulo no presenta {nombreRelacion}s.</p>
            )}
        </div >
    )
}