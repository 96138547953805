import React, { useEffect, useState } from 'react';
import { ButtonGroup, Col, Row, ToggleButton } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '../../generics/dialog';
import { error, success } from '../../store/alerts/alertActions';
import { searchFab } from '../../store/screen/screenActions';
import { Loader } from '../../ui/Loader';
import { ScreenWithFabButtons } from '../../ui/ScreenWithFabButtons';
import { SincroTable } from '../SincroTable/SincroTable';
import * as Service from "../SincronizacionService";
import { isAnyChecked } from '../utils/isAnyChecked';
import { sincronizacionConstants } from '../utils/sincronizacionConstants';
import Paginacion from '../../generics/Paginacion';
import Input from '../../ui/Input';
import InputDatePicker from '../../generics/InputDatePicker';
import { handlerChange, isValidNumeric } from '../../utils/commons';
import { LimitSelect } from '../../ui/LimitSelect/LimitSelect';

export const SincroAsignaturas = () => {
    const defaultInputsValues = {
        fecha_creacion_desde: null,
        fecha_creacion_hasta: null,
        fecha_migracion_desde: null,
        fecha_migracion_hasta: null,
        cod_dad: null,
    }
    const [inputsForm, setInputsForm] = useState(defaultInputsValues);

    const [searching, setSearching] = useState(false);
    const dispatch = useDispatch();
    const screenState = useSelector(st => st.screen);
    const [typeOfSearch, setTypeOfSearch] = useState(sincronizacionConstants.DISPONIBLES.id);

    const [headersTable, setHeadersTable] = useState()
    const [cantidadElementos, setCantidadElementos] = useState()
    const [page, setPage] = useState(1);
    const [asignaturas, setAsignaturas] = useState();
    const [selectedChecks, setSelectedChecks] = useState({});

    const buscarElementos = async (typeOfSearch, filtros = {}) => {
        try {
            setSearching(true)
            const getter = typeOfSearch == sincronizacionConstants.DISPONIBLES.id ?
                Service.getAsignaturas
                :
                Service.getAsignaturasPendientes

            const params = typeOfSearch == sincronizacionConstants.MIGRADOS.id ? { migrados: true } : {}

            const data = await getter({ ...params, page, ...filtros });
            setHeadersTable(data.headers);
            setCantidadElementos(data.cantidad);
            setPage(data.page)
            setAsignaturas(data.elementos)
        } catch (err) {
            dispatch(error("No fue posible cargar las asignaturas"))
        }
        finally {
            setSearching(false)
        }
    };

    const sincronizarElementos = async () => {
        try {
            const onlyCheckedIds = Object.keys(selectedChecks).filter(ctKey => selectedChecks[ctKey])
            await Service.crearSincronismoAsignaturas({ arrIds: onlyCheckedIds });
            dispatch(success("Sincronización realizada correctamente"))
            buscarElementos(typeOfSearch, inputsForm)
        } catch (err) {
            dispatch(error("No fue posible realizar la sincronización"))
        }
    }

    const handleClickTypeOfSearch = (typeOfSearch) => {
        setPage(1)
        setSelectedChecks({})
        setInputsForm(defaultInputsValues)
        setTypeOfSearch(typeOfSearch)
        buscarElementos(typeOfSearch, { ...inputsForm, page: 1 })
    }

    const handleChange = (e, elemento) => {
        if (Array.isArray(elemento)) {
            const changes = {}
            elemento.forEach(ctEl => { changes[ctEl.id] = e.target.checked })
            setSelectedChecks(st => ({ ...st, ...changes }))
        } else {
            setSelectedChecks(st => ({ ...st, [elemento.id]: e.target.checked }))
        }
    }

    const handleChangePage = (event, value) => {
        buscarElementos(typeOfSearch, { ...inputsForm, page: value })
    }

    const handleValidChange = (isValid, e) => {
        if (isValid(e.target.value)) {
            handlerChange(setInputsForm, inputsForm, null, e)

        }
    };

    const handleChangeDate = (date, controlId) => {
        const customEvent = { target: { id: controlId, value: date, } }
        handlerChange(setInputsForm, inputsForm, null, customEvent);
    }

    const handleChangeLimit = (limit) => {
        setInputsForm(st => {
            const nwSt = { ...st, limit, page: 1 }
            buscarElementos(typeOfSearch, nwSt);
            return nwSt
        })
    }

    useEffect(() => {
        buscarElementos(typeOfSearch)
    }, [])

    useEffect(() => {
        if (screenState.search) {
            buscarElementos(typeOfSearch, { ...inputsForm, page: 1 });
            dispatch(searchFab(false));
        }
    }, [screenState])


    return (
        <ScreenWithFabButtons
            hideSearchButton={false}
            searchButtonDisabled={searching}
        >
            <h1>Sincronización: Asignaturas</h1>
            <div className="d-flex justify-content-between">
                <div>
                    <h3>Buscar por</h3>
                    <ButtonGroup className="mb-2">
                        <ToggleButton
                            id={`radio-1`}
                            className="menu-radio"
                            type="radio"
                            variant="secondary"
                            name="radio"
                            value={sincronizacionConstants.DISPONIBLES.id}
                            checked={typeOfSearch == sincronizacionConstants.DISPONIBLES.id}
                            onChange={() => handleClickTypeOfSearch(sincronizacionConstants.DISPONIBLES.id)}
                        >
                            Disponibles
                        </ToggleButton>
                        <ToggleButton
                            id={`radio-2`}
                            className="menu-radio"
                            type="radio"
                            variant="secondary"
                            name="radio"
                            value={sincronizacionConstants.PENDIENTES.id}
                            checked={typeOfSearch == sincronizacionConstants.PENDIENTES.id}
                            onChange={() => handleClickTypeOfSearch(sincronizacionConstants.PENDIENTES.id)}
                        >
                            Pendientes
                        </ToggleButton>
                        <ToggleButton
                            id={`radio-2`}
                            className="menu-radio"
                            type="radio"
                            variant="secondary"
                            name="radio"
                            value={sincronizacionConstants.MIGRADOS.id}
                            checked={typeOfSearch == sincronizacionConstants.MIGRADOS.id}
                            onChange={() => handleClickTypeOfSearch(sincronizacionConstants.MIGRADOS.id)}
                        >
                            Migrados
                        </ToggleButton>
                    </ButtonGroup>
                </div>
                <LimitSelect
                    label="Filas por pagina"
                    handleChangeLimit={handleChangeLimit}
                />
            </div>
            <hr></hr>
            <Row>
                <Col sm={6} md={4} lg={3}>
                    <Input
                        type="input"
                        controlId="cod_dad"
                        label="Código DAD"
                        value={inputsForm.cod_dad ? inputsForm.cod_dad : ""}
                        onChange={handleValidChange.bind(this, isValidNumeric)}
                    ></Input>
                </Col>
                <Col sm={6} md={4} lg={3}>
                    <InputDatePicker
                        label={"Fecha creación desde"}
                        selected={inputsForm.fecha_creacion_desde}
                        onChange={date => handleChangeDate(date, "fecha_creacion_desde")}
                    />
                </Col>
                <Col sm={6} md={4} lg={3}>
                    <InputDatePicker
                        label={"Fecha creación hasta"}
                        selected={inputsForm.fecha_creacion_hasta}
                        onChange={date => handleChangeDate(date, "fecha_creacion_hasta")}
                    />
                </Col>
                {
                    typeOfSearch == sincronizacionConstants.MIGRADOS.id && <>
                        <Col sm={6} md={4} lg={3}>
                            <InputDatePicker
                                label={"Fecha migración desde"}
                                selected={inputsForm.fecha_migracion_desde}
                                onChange={date => handleChangeDate(date, "fecha_migracion_desde")}
                            />
                        </Col>
                        <Col sm={6} md={4} lg={3}>
                            <InputDatePicker
                                label={"Fecha migración hasta"}
                                selected={inputsForm.fecha_migracion_hasta}
                                onChange={date => handleChangeDate(date, "fecha_migracion_hasta")}
                            />
                        </Col>
                    </>
                }
            </Row>
            <hr></hr>
            <Row>
                <Col>
                    {searching && <Loader></Loader>}

                    {!searching && (asignaturas && headersTable && cantidadElementos > 0 ?
                        <>
                            <SincroTable
                                headersTable={headersTable}
                                elementos={asignaturas}
                                tableKey={"asignaturas"}
                                withCheckbox={typeOfSearch == sincronizacionConstants.DISPONIBLES.id}
                                handleChange={handleChange}
                                selectedChecks={selectedChecks}
                            />
                            <div class="d-flex justify-content-between align-items-start mb-3">
                                <Paginacion filasPorPaginas={inputsForm.limit} totalItems={cantidadElementos} currentPage={page} onChangePage={handleChangePage} />
                                {
                                    typeOfSearch == sincronizacionConstants.DISPONIBLES.id &&
                                    <Dialog
                                        action={sincronizarElementos}
                                        target={"asignaturas"}
                                        title="Confirmar sincronización"
                                        variant="success"
                                        size="md"
                                        btnConfirmText="Sincronizar"
                                        btnText="Sincronizar asignaturas"
                                        btnVariant="success"
                                        isLoadingText={"Sincronizando"}
                                        disabled={!isAnyChecked(selectedChecks)}
                                        body={<span>¿Estás seguro de sincronizar las asignaturas seleccionadas?</span>}
                                    />
                                }
                            </div>
                        </>
                        :
                        <p>
                            No hay elementos para sincronizar
                        </p>
                    )}
                </Col>
            </Row>
        </ScreenWithFabButtons>
    )
}
