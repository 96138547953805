import asignaturasIcon from "../images/asignaturas-icon.svg";
import cargosIcon from "../images/cargos-icon.svg";
import especialidadesIcon from "../images/especialidades-icon.svg";
import informesIcon from "../images/informes-icon.svg";
import intermediosIcon from "../images/intermedios-icon.svg";
import titulosIcon from "../images/titulos-icon.svg";
import troncalesIcon from "../images/troncales-icon.svg";
import usuariosIcon from "../images/usuarios-icon.svg";

import abmGeneralIcon from "../images/dash-icons/ABM-General.svg";
import abms from "../images/dash-icons/ABMs.svg";
import clonadorIcon from "../images/dash-icons/Clonador.svg";
import configuracionesIcon from "../images/dash-icons/Configuraciones.svg";
import equivalenciasIcon from "../images/dash-icons/Equivalencias.svg";
import incumbenciasGeneralIcon from "../images/dash-icons/Incumbencias-General.svg";
import informesGeneralIcon from "../images/dash-icons/informes-General.svg";
import ternasGeneralIcon from "../images/dash-icons/Terna-General.svg";

import { routePaths } from "./Constants";
import { PERMISOS_MAP } from "./Permisos";

export const menuDashboardOptions = [

    /* 
    //------ESQUEMA OBJETO CATEGORIA:------//
    {
        title: "ABMs",
        menuTitle: "Inicio / ABMs",
        menuIcon: cargosIcon,
        path: routePaths.HOME_ABMS,
        options: [
            {
                title: "Asignatura",
                icon: asignaturasIcon,
                path: routePaths.ABM_ASIGNATURAS_SEARCH,
                permisoRequired: PERMISOS_MAP.CONFIGURACIONES_VER,
            },
    },

    //------ESQUEMA OBJETO CATEGORIA:------//
    {
        title: "Ejemplo Link",
        path: routePaths.ABM_TITULOS_INTERMEDIOS_SEARCH,
        icon: titulosIcon,
        permisoRequired: PERMISOS_MAP.CONFIGURACIONES_VER,
    }

    Aclaracion: permisoRequired solo oculta icono. No se crea ruta automaticamente ni redirecciona al no tener permiso para eso ir a UserApp.js y agregar
                <PrivateRoute
                    exact
                    path={routePaths.ABM_TITULOS_EQUIVALENTES_EDIT}
                    permisos={PERMISOS_MAP.EQUIVALENTE_EDITAR}
                >
                    <EquivalenciaABMEdit />
                </PrivateRoute>
     */

    {
        title: "ABMs",
        menuTitle: "Inicio / ABMs",
        menuIcon: abms,
        path: routePaths.HOME_ABMS,
        // permisoRequired: PERMISOS_MAP.CONFIGURACIONES_VER,
        options: [
            {
                title: "Asignatura",
                icon: abmGeneralIcon,
                path: routePaths.ABM_ASIGNATURAS_SEARCH,
                permisoRequired: PERMISOS_MAP.VER_MODULO_ASIGNATURA,
            },
            {
                title: "Cargos",
                icon: abmGeneralIcon,
                path: routePaths.ABM_CARGOS_SEARCH,
                permisoRequired: PERMISOS_MAP.VER_MODULO_CARGO,
            },
            {
                title: "Especialidades",
                icon: abmGeneralIcon,
                path: routePaths.ABM_ESPECIALIDADES_SEARCH,
                permisoRequired: PERMISOS_MAP.VER_MODULO_ESPECIALIDAD,
            },
            {
                title: "Tipo de Escuela",
                icon: abmGeneralIcon,
                path: routePaths.ABM_ESCUELA_SEARCH,
                permisoRequired: PERMISOS_MAP.VER_MODULO_TIPO_ESCUELA,
            },
            {
                title: "Apendice",
                icon: abmGeneralIcon,
                path: routePaths.ABM_APENDICE_SEARCH,
                permisoRequired: PERMISOS_MAP.VER_MODULO_APENDICE,
            },
        ]
    },
    {
        title: "Ternas",
        menuTitle: "Inicio / Ternas",
        menuIcon: ternasGeneralIcon,
        path: routePaths.HOME_TERNAS,
        // permisoRequired: PERMISOS_MAP.CONFIGURACIONES_VER,
        options: [
            {
                title: "Ternas",
                icon: ternasGeneralIcon,
                path: routePaths.ABM_TERNA_SEARCH,
                // permisoRequired: PERMISOS_MAP.CONFIGURACIONES_VER,
            },
            {
                title: "Equivalencias",
                icon: equivalenciasIcon,
                path: routePaths.ABM_TITULOS_EQUIVALENTES_SEARCH,
                // permisoRequired: PERMISOS_MAP.CONFIGURACIONES_VER,
            },
            {
                title: "Títulos ITF",
                icon: ternasGeneralIcon,
                path: routePaths.ABM_TITULOS_ITF_SEARCH,
                // permisoRequired: PERMISOS_MAP.CONFIGURACIONES_VER,
            },
            {
                title: "Unificador",
                icon: abmGeneralIcon,
                path: routePaths.ABM_TERNA_UNIFICADOR_SEARCH,
                // permisoRequired: PERMISOS_MAP.INCUMBENCIA_UNIFICAR,
            },
            {
                title: "Ternas Unificadas",
                icon: ternasGeneralIcon,
                path: routePaths.ABM_TERNAS_UNIFICADAS_SEARCH,
                // permisoRequired: PERMISOS_MAP.CONFIGURACIONES_VER,
            },
        ]
    },
    {
        title: "Incumbencias",
        menuTitle: "Inicio / Incumbencias",
        menuIcon: incumbenciasGeneralIcon,
        path: routePaths.HOME_INCUMBENCIAS,
        // permisoRequired: PERMISOS_MAP.CONFIGURACIONES_VER,
        options: [
            {
                title: "Incumbencias",
                icon: incumbenciasGeneralIcon,
                path: routePaths.ABM_INCUMBENCIAS_SEARCH_TERNAS,
                // permisoRequired: PERMISOS_MAP.CONFIGURACIONES_VER,
            },
            {
                title: "Clonador",
                icon: clonadorIcon,
                path: routePaths.ABM_INCUMBENCIAS_CLONADOR_TERNAS,
                permisoRequired: PERMISOS_MAP.INCUMBENCIA_CLONAR,
            },
            {
                title: "Unificador",
                icon: abmGeneralIcon,
                path: routePaths.ABM_INCUMBENCIAS_UNIFICADOR_SEARCH,
                // permisoRequired: PERMISOS_MAP.INCUMBENCIA_UNIFICAR,
            },
        ]
    },
    {
        title: "Configuraciones",
        menuTitle: "Inicio / Configuraciones",
        menuIcon: configuracionesIcon,
        path: routePaths.HOME_CONFIGURACIONES,
        permisoRequired: PERMISOS_MAP.VER_MODULO_CONFIGURACION,
        options: [
            {
                title: "Usuarios",
                icon: configuracionesIcon,
                path: routePaths.ABM_USER_SEARCH,
                permisoRequired: PERMISOS_MAP.VER_MODULO_USUARIO,
            },
            // {
            //     title: "Sincronizacion",
            //     icon: configuracionesIcon,
            //     path: routePaths.HOME_SINCRONIZACIONES,
            //     permisoRequired: PERMISOS_MAP.VER_MODULO_SINCRONIZACION,
            // },
        ]
    },
    {
        title: "Sincronización",
        menuTitle: "Inicio / Sincronización",
        menuIcon: configuracionesIcon,
        path: routePaths.HOME_SINCRONIZACIONES,
        permisoRequired: PERMISOS_MAP.VER_MODULO_SINCRONIZACION,
        options: [
            {
                title: "Ternas",
                icon: abmGeneralIcon,
                path: routePaths.SINCRO_PANEL_TERNAS,
                permisoRequired: PERMISOS_MAP.VER_MODULO_SINCRONIZACION,
            },
            {
                title: "Incumbencias",
                icon: abmGeneralIcon,
                path: routePaths.SINCRO_PANEL_INCUMBENCIAS,
                permisoRequired: PERMISOS_MAP.VER_MODULO_SINCRONIZACION,
            },
            {
                title: "Cargos",
                icon: abmGeneralIcon,
                path: routePaths.SINCRO_PANEL_CARGOS,
                permisoRequired: PERMISOS_MAP.VER_MODULO_SINCRONIZACION,
            },
            {
                title: "Asignaturas",
                icon: abmGeneralIcon,
                path: routePaths.SINCRO_PANEL_ASIGNATURAS,
                permisoRequired: PERMISOS_MAP.VER_MODULO_SINCRONIZACION,
            },
            {
                title: "Especialidades",
                icon: abmGeneralIcon,
                path: routePaths.SINCRO_PANEL_ESPECIALIDADES,
                permisoRequired: PERMISOS_MAP.VER_MODULO_SINCRONIZACION,
            },

        ]
    },

    {
        title: "Informes",
        menuTitle: "Inicio / Informes",
        menuIcon: informesGeneralIcon,
        path: routePaths.HOME_INFORMES,
        permisoRequired: PERMISOS_MAP.VER_MODULO_INFORME,
        options: [
            {
                title: <>Ternas</>,
                icon: informesGeneralIcon,
                path: routePaths.INFORMES_SEARCH_TERNAS
                // permisoRequired: PERMISOS_MAP.CONFIGURACIONES_VER,
            },
            {
                title: <>Incumbencias<br></br>de Títulos</>,
                icon: informesGeneralIcon,
                path: routePaths.INFORMES_SEARCH_INCUMBENCIAS_BY_TERNA
                // permisoRequired: PERMISOS_MAP.CONFIGURACIONES_VER,
            },
            {
                title: <>Búsqueda por<br></br>Cargo o Materia</>,
                icon: informesGeneralIcon,
                path: routePaths.INFORMES_SEARCH_TERNAS_BY_AREA_CARGO
                // permisoRequired: PERMISOS_MAP.CONFIGURACIONES_VER,
            },
            {
                title: <>Vademecum</>,
                icon: informesGeneralIcon,
                path: routePaths.INFORMES_SEARCH_CRUZADO
                // permisoRequired: PERMISOS_MAP.CONFIGURACIONES_VER,
            },
            {
                title: <>Topologicos</>,
                icon: informesGeneralIcon,
                path: routePaths.INFORMES_SEARCH_TOPOLOGICO
                // permisoRequired: PERMISOS_MAP.CONFIGURACIONES_VER,
            },
        ]
    },
    {
        title: "Tickets",
        path: routePaths.ABM_TICKETS_SEARCH_PENDIENTES,
        icon: titulosIcon,
        // permisoRequired: PERMISOS_MAP.CONFIGURACIONES_VER,
    }
]

export {
    asignaturasIcon, cargosIcon, equivalenciasIcon, especialidadesIcon, informesIcon,
    intermediosIcon,
    titulosIcon,
    troncalesIcon,
    usuariosIcon
};

