import React from "react";
import { Table, Row, Col, Alert } from "react-bootstrap";
import { FormTableRowByTerna } from "./FormTableRowByTerna";
import Paginacion from "../../generics/Paginacion";
const { tipoBusquedaInforme } = require("../../utils/Constants");

const FormTableHeader = ({ tipoBusqueda }) => {
  return (
    <tr>
      <th>Nombre de título</th>
      {/* <th>Nivel</th> */}
      <th>Procedencia</th>
      <th>Resolución</th>
      {/* <th>Creación</th> */}
      <th>Estado</th>
    </tr>
  )

  // switch (tipoBusqueda) {
  //   case tipoBusquedaInforme.TITULO:
  //     return (
  //       <tr>
  //         <th>Nombre de título</th>
  //         <th>Nivel</th>
  //         <th>Procedencia</th>
  //         <th>Resolución</th>
  //         <th>Creación</th>
  //         <th>Estado</th>
  //       </tr>
  //     )
  //   case tipoBusquedaInforme.RESOLUCIONES:
  //     return (
  //       <tr>
  //         <th>Nro de resoluciones</th>
  //         <th>Tipo de documento</th>
  //         <th>Organismo</th>
  //         <th>Año</th>
  //         <th>Creación</th>
  //         <th></th>
  //       </tr>
  //     )
  //   case tipoBusquedaInforme.PROCEDENCIAS:
  //     return (
  //       <tr>
  //         <th>Nombre de institución</th>
  //         <th>Creación</th>
  //         <th></th>
  //       </tr>
  //     )
  //   default:
  //     return <></>
  // }
};

export const FormTableByTerna = ({
  elementos,
  tipoBusqueda = tipoBusquedaInforme.TITULO,
  cantidadElementos,
  page,
  handleChangePage,
  onSubmit = () => console.log("TODO: DEFINIR onSubmit"),
}) => {

  /* 
  TODO: DEFINIR onSubmit
  */

  return (
    <>
      {cantidadElementos > 0 && <>

        <Row className="form-group">
          <Col>
            <Table responsive>
              <thead>
                <FormTableHeader tipoBusqueda={tipoBusqueda} />
              </thead>
              <tbody>
                {
                  elementos.map(terna =>
                    <React.Fragment key={terna.id} >
                      <FormTableRowByTerna
                        onSubmit={onSubmit}
                        terna={terna}
                        tipoBusqueda={tipoBusqueda}
                      />
                    </React.Fragment>)
                }
              </tbody>
            </Table>
          </Col>
        </Row>
        <Paginacion totalItems={cantidadElementos} currentPage={page} onChangePage={handleChangePage} />
      </>}

      {cantidadElementos === 0 && <Alert variant="info">No se encontraron informes.</Alert>}
    </>
  );
};
