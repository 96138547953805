import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import Input from '../../ui/Input'
import * as cargoService from "../../cargos/CargosService";
import { handlerChange, isValidText } from '../../utils/commons';
import { useDispatch, useSelector } from 'react-redux';
import { cleanFab, searchFab } from '../../store/screen/screenActions';
import { useRef } from 'react';
import { clear } from '../../store/alerts/alertActions';
import * as asignaturaService from "../../asignaturas/AsignaturaService";
import * as especialidadesService from "../../especialidades/EspecialidadesService"
import CheckBoxEstados from '../../generics/CheckBoxEstados';


export const FormSearchCruzadosCargo = ({
	setInputsForm,
	inputsForm,
	searchCruzadosCargo,
	resetForm,
	resetChecks,
	setResetChecks
}) => {
	const [cargoSeleccionado, setCargoSeleccionado] = useState("");
	const [asignaturaSeleccionada, setAsignaturaSeleccionada] = useState("");
	const [especialidadSeleccionada, setEspecialidadSeleccionada] = useState("");

	const screenState = useSelector(st => st.screen);
	const dispatch = useDispatch();

	const isMountedRef = useRef(true);

	const resetStates = () => {
		setCargoSeleccionado("");
		setAsignaturaSeleccionada("")
	}

	const handleChange = (e) => {
		console.log({ e })
		handlerChange(setInputsForm, inputsForm, null, e)
	};

	const processSubmit = () => {
		searchCruzadosCargo(inputsForm, isMountedRef);
	}

	const loadDefaultValues = () => {
		const eDefault = { target: { id: "cargo_id", label: "PROFESOR DE", value: 105 } }
		const cargoDefault = { value: 105, label: "PROFESOR DE" }
		handlerChange(setInputsForm, inputsForm, null, eDefault)
		setCargoSeleccionado(cargoDefault)
	}

	useEffect(() => {
		loadDefaultValues();
		isMountedRef.current = true;
		dispatch(clear());
		return () => (isMountedRef.current = false);
	}, [dispatch]);

	useEffect(() => {
		if (screenState.clean) {
			resetForm();
			resetStates();
			loadDefaultValues()
			dispatch(cleanFab(false));
		}

		if (screenState.search) {
			processSubmit();
			dispatch(searchFab(false));
		}

	}, [screenState])

	return (
		<Form
			onSubmit={(e) => e.preventDefault()}
			className="w-100"
		>
			<Row>
				<Col sm={12} md={6} lg={4}>
					<Input
						type="react-select"
						controlId="cargo_id"
						name="cargo_id"
						label="Nombre del cargo"
						value={cargoSeleccionado}
						paramsFilter={{ min: true, all: false }}
						methodOnChange={handleChange}
						elements={cargoService.getCargos}
						nameElementsResponse="data"
						propertiesForFormat={{ value: "id", label: "nombre" }}
						setterElementSelect={setCargoSeleccionado}
						config={{ isSearchable: true, isClearable: true }}
						predictivoAsync={true}
						withBusquedaAvanzada={true}

					></Input>
				</Col>
				<Col sm={12} md={6} lg={4}>
					<Input
						type="react-select"
						controlId="asignatura_id"
						name="asignatura_id"
						label="Nombre de la asignatura"
						value={asignaturaSeleccionada}
						methodOnChange={handleChange}
						elements={asignaturaService.getAsignaturas}
						nameElementsResponse="elementos"
						propertiesForFormat={{ value: "id", label: "nombre" }}
						setterElementSelect={setAsignaturaSeleccionada}
						config={{ isSearchable: true, isClearable: true }}
						withBusquedaAvanzada={true}
					></Input>
				</Col>
				<Col sm={12} md={6} lg={4}>
					<Input
						type="react-select"
						controlId="especialidad_id"
						name="especialidad_id"
						label="Nombre de la especialidad"
						value={especialidadSeleccionada}
						methodOnChange={handleChange}
						elements={especialidadesService.getEspecialidades}
						nameElementsResponse="data"
						propertiesForFormat={{ value: "id", label: "nombre" }}
						setterElementSelect={setEspecialidadSeleccionada}
						config={{ isSearchable: true, isClearable: true }}
						withBusquedaAvanzada={true}
					></Input>
				</Col>
				{/* <Col sm={12} md={6} lg={4}>
						<Input
								type="checkbox"
								controlId="incluirDeshabilitados"
								name="incluirDeshabilitados"
								label="Incluir deshabilitados"
								checked={inputsForm.incluirDeshabilitados ? inputsForm.incluirDeshabilitados : ""}
								onChange={handleChangeCheckBox}
								style={{ width: "fit-content" }}
						></Input>
					</Col> 
				*/}
			</Row>
			<Row className="form-group">
				<Col sm={12}>
					<CheckBoxEstados
						inputsForm={inputsForm}
						setInputsForm={setInputsForm}
						resetChecks={resetChecks}
						setResetChecks={setResetChecks}
					/>
				</Col>
			</Row>
		</Form >
	)
}
