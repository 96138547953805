import { faArrowLeft, faBroom, faPlus } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import PrivateComponent from '../authentication/PrivateComponent'
import { GlassIcon } from '../images/svg-jsx/GlassIcon'
import { clear } from '../store/alerts/alertActions'
import { cleanFab, resetFab, searchFab } from '../store/screen/screenActions'
import { clearFilters } from '../store/search/searchActions'
import { ticketsUtils } from '../tickets/TicketsUtils'
import { TicketsCreateModal } from '../tickets/abm/TicketsCreateModal'
import { routePaths } from '../utils/Constants'
import { PERMISOS_MAP } from '../utils/Permisos'
import FabButton from './FabButton'

const defaultReturnMapa = {
    /* RUTA ACTUAL   :  RUTA A BACKEAR   */

    /* HOME MAP */
    [routePaths.HOME_ABMS]: routePaths.HOME,
    [routePaths.HOME_TERNAS]: routePaths.HOME,
    [routePaths.HOME_CONFIGURACIONES]: routePaths.HOME,
    [routePaths.HOME_INFORMES]: routePaths.HOME,

    /* ASIGNATURAS MAP */
    [routePaths.ABM_ASIGNATURAS_SEARCH]: routePaths.HOME_ABMS,
    [routePaths.ABM_ASIGNATURAS_CREATE]: routePaths.ABM_ASIGNATURAS_SEARCH,
    [routePaths.ABM_ASIGNATURAS_EDIT]: routePaths.ABM_ASIGNATURAS_SEARCH,
    [routePaths.ABM_ASIGNATURAS_APPROVAL]: routePaths.ABM_ASIGNATURAS_SEARCH,

    /* CARGOS MAP */
    [routePaths.ABM_CARGOS_SEARCH]: routePaths.HOME_ABMS,
    [routePaths.ABM_CARGOS_CREATE]: routePaths.ABM_CARGOS_SEARCH,
    [routePaths.ABM_CARGOS_EDIT]: routePaths.ABM_CARGOS_SEARCH,
    [routePaths.ABM_CARGOS_APPROVAL]: routePaths.ABM_CARGOS_SEARCH,

    /* ESPECIALIDADES MAP */
    [routePaths.ABM_ESPECIALIDADES_SEARCH]: routePaths.HOME_ABMS,
    [routePaths.ABM_ESPECIALIDADES_CREATE]: routePaths.ABM_ESPECIALIDADES_SEARCH,
    [routePaths.ABM_ESPECIALIDADES_EDIT]: routePaths.ABM_ESPECIALIDADES_SEARCH,
    [routePaths.ABM_ESPECIALIDADES_APPROVAL]: routePaths.ABM_ESPECIALIDADES_SEARCH,

    /* TIPO ESCUELA */
    [routePaths.ABM_ESCUELA_CREATE]: routePaths.ABM_ESCUELA_SEARCH,
    [routePaths.ABM_ESCUELA_EDIT]: routePaths.ABM_ESCUELA_SEARCH,
    [routePaths.ABM_ESCUELA_SEARCH]: routePaths.HOME_ABMS,
    [routePaths.ABM_ESCUELA_APPROVAL]: routePaths.ABM_ESCUELA_SEARCH,


    /* APENDICE MAP */
    [routePaths.ABM_APENDICE_SEARCH]: routePaths.HOME_ABMS,
    [routePaths.ABM_APENDICE_EDIT]: routePaths.ABM_APENDICE_SEARCH,
    [routePaths.ABM_APENDICE_CREATE]: routePaths.ABM_APENDICE_SEARCH,


    /* INCUMBENCIAS MAP */
    [routePaths.ABM_INCUMBENCIAS_CREATE_TERNAS]: routePaths.ABM_INCUMBENCIAS_SEARCH_TERNAS,
    [routePaths.ABM_INCUMBENCIAS_CREATE_CARGOS]: routePaths.ABM_INCUMBENCIAS_SEARCH_CARGOS,
    [routePaths.ABM_INCUMBENCIAS_EDIT_TERNAS]: routePaths.ABM_INCUMBENCIAS_SEARCH_TERNAS,
    [routePaths.ABM_INCUMBENCIAS_EDIT_CARGOS]: routePaths.ABM_INCUMBENCIAS_SEARCH_CARGOS,
    [routePaths.ABM_INCUMBENCIAS_SEARCH_TERNAS]: routePaths.HOME_INCUMBENCIAS,
    [routePaths.ABM_INCUMBENCIAS_SEARCH_CARGOS]: routePaths.HOME_INCUMBENCIAS,
    [routePaths.ABM_INCUMBENCIAS_CLONADOR]: routePaths.HOME_INCUMBENCIAS,
    [routePaths.ABM_INCUMBENCIAS_UNIFICADOR_CREATE]: routePaths.ABM_INCUMBENCIAS_UNIFICADOR_SEARCH,
    [routePaths.ABM_INCUMBENCIAS_UNIFICADOR_SEARCH]: routePaths.HOME_INCUMBENCIAS,
    [routePaths.HOME_INCUMBENCIAS]: routePaths.HOME,

    /* TERNAS */
    [routePaths.ABM_TERNA_SEARCH]: routePaths.HOME_TERNAS,
    [routePaths.ABM_TERNA_CREATE]: routePaths.ABM_TERNA_SEARCH,
    [routePaths.ABM_TERNA_EDIT]: routePaths.ABM_TERNA_SEARCH,
    [routePaths.ABM_TERNA_APPROVAL]: routePaths.ABM_TERNA_SEARCH,
    [routePaths.ABM_TERNA_UNIFICADOR_CREATE]: routePaths.ABM_TERNA_UNIFICADOR_SEARCH,
    [routePaths.ABM_TERNA_UNIFICADOR_SEARCH]: routePaths.HOME_TERNAS,

    /* INTERMEDIOS */
    [routePaths.ABM_TITULOS_INTERMEDIOS_SEARCH]: routePaths.HOME_TERNAS,
    [routePaths.ABM_TITULOS_INTERMEDIOS_CREATE]: routePaths.ABM_TITULOS_INTERMEDIOS_SEARCH,
    [routePaths.ABM_TITULOS_INTERMEDIOS_CREATE_SEARCH_ORIGINAL]: routePaths.ABM_TITULOS_INTERMEDIOS_CREATE,
    [routePaths.ABM_TITULOS_INTERMEDIOS_CREATE_SEARCH_FINAL]: routePaths.ABM_TITULOS_INTERMEDIOS_CREATE,
    [routePaths.ABM_TITULOS_INTERMEDIOS_EDIT]: routePaths.ABM_TITULOS_INTERMEDIOS_SEARCH,
    [routePaths.ABM_TITULOS_INTERMEDIOS_APPROVAL]: routePaths.ABM_TITULOS_INTERMEDIOS_SEARCH,

    /* EQUIVALENTE */
    [routePaths.ABM_TITULOS_EQUIVALENTES_SEARCH]: routePaths.HOME_TERNAS,
    [routePaths.ABM_TITULOS_EQUIVALENTES_CREATE]: routePaths.ABM_TITULOS_EQUIVALENTES_SEARCH,
    [routePaths.ABM_TITULOS_EQUIVALENTES_CREATE_SEARCH_EQUIVALENTE]: routePaths.ABM_TITULOS_EQUIVALENTES_CREATE,
    [routePaths.ABM_TITULOS_EQUIVALENTES_CREATE_SEARCH_ORIGINAL]: routePaths.ABM_TITULOS_EQUIVALENTES_CREATE,
    [routePaths.ABM_TITULOS_EQUIVALENTES_EDIT]: routePaths.ABM_TITULOS_EQUIVALENTES_SEARCH,
    [routePaths.ABM_TITULOS_EQUIVALENTES_APPROVAL]: routePaths.ABM_TITULOS_EQUIVALENTES_SEARCH,

    /* ITF */
    [routePaths.ABM_TITULOS_ITF_SEARCH]: routePaths.HOME_TERNAS,
    [routePaths.ABM_TITULOS_ITF_CREATE]: routePaths.ABM_TITULOS_ITF_SEARCH,
    [routePaths.ABM_TITULOS_ITF_EDIT]: routePaths.ABM_TITULOS_ITF_SEARCH,

    /* TRONCALES */
    [routePaths.ABM_TITULOS_TRONCALES_SEARCH]: routePaths.HOME_TERNAS,
    [routePaths.ABM_TITULOS_TRONCALES_CREATE]: routePaths.ABM_TITULOS_TRONCALES_SEARCH,
    [routePaths.ABM_TITULOS_TRONCALES_CREATE_SEARCH_TRONCAL]: routePaths.ABM_TITULOS_TRONCALES_CREATE,
    [routePaths.ABM_TITULOS_TRONCALES_CREATE_SEARCH_ORIENTACION]: routePaths.ABM_TITULOS_TRONCALES_CREATE,
    [routePaths.ABM_TITULOS_TRONCALES_EDIT]: routePaths.ABM_TITULOS_TRONCALES_SEARCH,
    [routePaths.ABM_TITULOS_TRONCALES_APPROVAL]: routePaths.ABM_TITULOS_TRONCALES_SEARCH,

    /* USUARIOS */
    [routePaths.ABM_USER_SEARCH]: routePaths.HOME_CONFIGURACIONES,
    [routePaths.ABM_USER_CREATE]: routePaths.ABM_USER_SEARCH,
    [routePaths.ABM_USER_EDIT]: routePaths.ABM_USER_SEARCH,

    /* INFORMES */
    [routePaths.INFORMES_SEARCH_TERNAS]: routePaths.HOME_INFORMES,
    [routePaths.INFORMES_SEARCH_INCUMBENCIAS_BY_TERNA]: routePaths.HOME_INFORMES,
    [routePaths.INFORMES_SEARCH_TERNAS_BY_AREA_CARGO]: routePaths.HOME_INFORMES,
    [routePaths.INFORMES_SEARCH_CRUZADO]: routePaths.HOME_INFORMES,
    [routePaths.INFORMES_SEARCH_TOPOLOGICO]: routePaths.HOME_INFORMES,
    [routePaths.INFORMES_SEARCH_INCUMBENCIAS_BY_TERNA_DETAIL]: routePaths.INFORMES_SEARCH_INCUMBENCIAS_BY_TERNA,
    [routePaths.INFORMES_SEARCH_TERNAS_BY_AREA_CARGO_DETAIL]: routePaths.INFORMES_SEARCH_TERNAS_BY_AREA_CARGO,

    /* SINCRONIZACIONES */
    [routePaths.HOME_SINCRONIZACIONES]: routePaths.HOME_CONFIGURACIONES,
    [routePaths.SINCRO_PANEL]: routePaths.HOME_SINCRONIZACIONES,
    [routePaths.SINCRO_PANEL_TERNAS]: routePaths.HOME_SINCRONIZACIONES,
    [routePaths.SINCRO_PANEL_CARGOS]: routePaths.HOME_SINCRONIZACIONES,
    [routePaths.SINCRO_PANEL_INCUMBENCIAS]: routePaths.HOME_SINCRONIZACIONES,
    [routePaths.SINCRO_PANEL_ASIGNATURAS]: routePaths.HOME_SINCRONIZACIONES,
    [routePaths.SINCRO_PANEL_ESPECIALIDADES]: routePaths.HOME_SINCRONIZACIONES,

    /* TICKETS */
    // [routePaths.INFORMES_SEARCH_TERNAS]: routePaths.HOME_INFORMES,


}

const defaultPlusMapa = {
    [routePaths.ABM_INCUMBENCIAS_SEARCH_TERNAS]: routePaths.ABM_INCUMBENCIAS_CREATE_TERNAS,
    [routePaths.ABM_INCUMBENCIAS_SEARCH_CARGOS]: routePaths.ABM_INCUMBENCIAS_CREATE_CARGOS,
    [routePaths.ABM_CARGOS_SEARCH]: routePaths.ABM_CARGOS_CREATE,
    [routePaths.ABM_ASIGNATURAS_SEARCH]: routePaths.ABM_ASIGNATURAS_CREATE,
    [routePaths.ABM_ESPECIALIDADES_SEARCH]: routePaths.ABM_ESPECIALIDADES_CREATE,
    [routePaths.ABM_TERNA_SEARCH]: routePaths.ABM_TERNA_CREATE,
    [routePaths.ABM_ESCUELA_SEARCH]: routePaths.ABM_ESCUELA_CREATE,
    [routePaths.ABM_USER_SEARCH]: routePaths.ABM_USER_CREATE,
    [routePaths.ABM_TITULOS_TRONCALES_SEARCH]: routePaths.ABM_TITULOS_TRONCALES_CREATE,
    [routePaths.ABM_TITULOS_INTERMEDIOS_SEARCH]: routePaths.ABM_TITULOS_INTERMEDIOS_CREATE,
    [routePaths.ABM_TITULOS_EQUIVALENTES_SEARCH]: routePaths.ABM_TITULOS_EQUIVALENTES_CREATE,
    [routePaths.ABM_APENDICE_SEARCH]: routePaths.ABM_APENDICE_CREATE,
    [routePaths.ABM_INCUMBENCIAS_UNIFICADOR_SEARCH]: routePaths.ABM_INCUMBENCIAS_UNIFICADOR_CREATE,
    [routePaths.ABM_TERNA_UNIFICADOR_SEARCH]: routePaths.ABM_TERNA_UNIFICADOR_CREATE,
}

const defaultPlusPermisos = {
    [routePaths.ABM_ASIGNATURAS_CREATE]: PERMISOS_MAP.ASIGNATURA_CREAR,
    [routePaths.ABM_CARGOS_CREATE]: PERMISOS_MAP.CARGO_CREAR,
    [routePaths.ABM_ESPECIALIDADES_CREATE]: PERMISOS_MAP.ESPECIALIDAD_CREAR,
    [routePaths.ABM_ESCUELA_CREATE]: PERMISOS_MAP.TIPO_ESCUELA_CREAR,
    [routePaths.ABM_APENDICE_CREATE]: PERMISOS_MAP.APENDICE_CREAR,

    [routePaths.ABM_TERNA_CREATE]: PERMISOS_MAP.TERNA_CREAR,
    [routePaths.ABM_TITULOS_EQUIVALENTES_CREATE]: PERMISOS_MAP.TITULO_EQUIVALENTE_CREAR,
    [routePaths.ABM_TITULOS_ITF_CREATE]: PERMISOS_MAP.TITULO_ITF_CREAR,

    [routePaths.ABM_TICKETS_SEARCH]: PERMISOS_MAP.TICKET_CREAR,
    [routePaths.ABM_TICKETS_SEARCH_PENDIENTES]: PERMISOS_MAP.TICKET_CREAR,
    [routePaths.ABM_TICKETS_SEARCH_RESUELTOS]: PERMISOS_MAP.TICKET_CREAR,
    [routePaths.ABM_TERNA_TICKET]: PERMISOS_MAP.TICKET_CREAR,

    [routePaths.ABM_INCUMBENCIAS_CREATE_TERNAS]: PERMISOS_MAP.INCUMBENCIA_CREAR,
    [routePaths.ABM_INCUMBENCIAS_CREATE_CARGOS]: PERMISOS_MAP.INCUMBENCIA_CREAR,
}

const nombreModuloMapa = {
    [routePaths.ABM_INCUMBENCIAS_SEARCH_TERNAS]: ticketsUtils.OPTIONS_MODULOS_NAME.INCUMBENCIAS,
    [routePaths.ABM_TERNA_SEARCH]: ticketsUtils.OPTIONS_MODULOS_NAME.TERNAS,
}

export const ScreenWithFabButtonsCopy = ({
    children,
    showFabButtons = true,
    returnCallBack,
    returnPath,
    returnState,
    hideReturnButton = false,
    hideCleanButton = false,
    hideSearchButton = false,
    hidePlusButton = false,
    hideNewTicketButton = true,
    plusCallBack,
    plusPath,
    plusPermisos,
    searchButtonDisabled = false,
    cleanButtonDisabled = false,
    containerFluid = false,
    onSearch,
    onClean,
}) => {


    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const alert = useSelector(st => st.alert)

    const handleClickReturn = () => {
        if (returnCallBack) {
            returnCallBack()
        } else {
            history.push(returnPath || defaultReturnMapa[location.pathname] || routePaths.HOME, returnState)
        }
    }

    const handleClickSearch = () => {
        if (onSearch) onSearch();
        dispatch(clearFilters())
        dispatch(searchFab(true))
        dispatch(clear());
    }

    const handleClickClean = () => {
        if (onClean) onClean();
        dispatch(cleanFab(true))
    }

    const handleClickPlus = () => {
        if (plusCallBack) {
            plusCallBack()
        } else {
            history.push(plusPath || defaultPlusMapa[location.pathname]);
        }
    }

    useEffect(() => {
        return () => {
            if (alert?.show) dispatch(clear());
            dispatch(resetFab());
        }
    }, [])

    return (
        <Row className={`flex-grow-1 m-0 ${containerFluid ? "col-fluid" : "container mx-auto"}`}>
            <Col className={`d-flex flex-column justify-content-between ${containerFluid ? "col-fluid" : "container"}`}>
                <div>
                    {children}
                </div>
                <div className={`FabButtonContainer mb-4 container`}>
                    {/* Botones FAB */}
                    {!hideReturnButton && showFabButtons && <FabButton
                        icon={faArrowLeft}
                        size="sm"
                        className="FabButton"
                        variant="secondary"
                        onClick={handleClickReturn}
                    ></FabButton>}

                    {!hideCleanButton && showFabButtons && <FabButton
                        icon={faBroom}
                        size="sm"
                        className="FabButton"
                        variant="secondary"
                        disabled={cleanButtonDisabled}
                        onClick={handleClickClean}
                    ></FabButton>}

                    {!hideSearchButton && showFabButtons && <FabButton
                        iconJsx={<GlassIcon />}
                        size="sm"
                        className="FabButton"
                        variant="secondary"
                        disabled={searchButtonDisabled}
                        onClick={handleClickSearch}
                    ></FabButton>}

                    {!hidePlusButton && showFabButtons && (plusCallBack || plusPath || defaultPlusMapa[location.pathname]) &&
                        <PrivateComponent PrivateComponent permisos={plusPermisos || defaultPlusPermisos[defaultPlusMapa[location.pathname]]}>
                            <FabButton
                                icon={faPlus}
                                size="lg"
                                className="FabButton"
                                onClick={handleClickPlus}
                            ></FabButton>
                        </PrivateComponent>
                    }
                </div>
            </Col>
        </Row >
    )
}

