import React, { useEffect, useRef, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import Input from '../../ui/Input';
import { handlerChange } from '../../utils/commons';
import * as ternaService from "../../ternas/TernaService";
import { useDispatch, useSelector } from 'react-redux'
import { cleanFab, searchFab } from '../../store/screen/screenActions'
import { saveFilters } from '../../store/search/searchActions'
import { reactSelectFormUtils } from '../../utils/reactSelectFormUtils'

export const SearchTernaToSincro = ({
  inputsForm = {},
  setInputsForm,
  cleanResults,

}) => {
  const [resolucionSeleccionada, setResolucionSeleccionada] = useState("");
  const [procedenciaSeleccionada, setProcedenciaSeleccionada] = useState("");
  const [tituloSeleccionado, setTituloSeleccionado] = useState("");
  const [resetChecks, setResetChecks] = useState(0);

  const reactSelectsInputs = [
    { name: "resolucionSeleccionada", state: resolucionSeleccionada, setter: setResolucionSeleccionada },
    { name: "procedenciaSeleccionada", state: procedenciaSeleccionada, setter: setProcedenciaSeleccionada },
    { name: "tituloSeleccionado", state: tituloSeleccionado, setter: setTituloSeleccionado },
  ]

  const cleanRelatedFiltersRef = useRef(false)

  const screenState = useSelector(st => st.screen);
  const searchState = useSelector(st => st.search);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    handlerChange(setInputsForm, inputsForm, null, e)
  };

  const resetForm = () => {
    reactSelectFormUtils.resetAllReactSelectStates(reactSelectsInputs);
    cleanResults();
    setResetChecks(st => st + 1)
  }

  useEffect(() => {
    if (screenState.clean) {
      resetForm()
      dispatch(cleanFab(false));
    }

    if (screenState.search) {
      const reactSelectValues = reactSelectFormUtils.getAllReactSelectStates(reactSelectsInputs);
      let filtros = { ...inputsForm, page: 1 };
      if (!filtros.titulos) filtros.titulos = false;
      if (!filtros.otros_titulos) filtros.otros_titulos = false;
      setInputsForm(filtros)
      dispatch(saveFilters({ reactSelectValues, filtros }));
      // searchTernas(filtros);
      dispatch(searchFab(false));
    }
  }, [screenState])

  useEffect(() => {
    if (Object.keys(searchState).length > 0) {
      if (searchState.reactSelectValues) reactSelectFormUtils.putAllReactSelectStates(reactSelectsInputs, searchState.reactSelectValues);
      if (searchState.filtros) setInputsForm(searchState.filtros);
    }
    cleanRelatedFiltersRef.current = true;
  }, []);

  return (
    <Form
      onSubmit={(e) => e.preventDefault()}
      className="w-100"
    >
      <Row>
        <Col sm={12} md={6} lg={3}>
          <Input
            type="react-select"
            controlId="titulo"
            name="titulo"
            label="Nombre de título"
            value={tituloSeleccionado}
            methodOnChange={handleChange}
            elements={ternaService.getTitulos}
            nameElementsResponse="elementos"
            propertiesForFormat={{ value: "id", label: "nombre" }}
            setterElementSelect={setTituloSeleccionado}
            config={{ isSearchable: true, isClearable: true, withAsyncDelay: true }}
            withBusquedaAvanzada={true}
          ></Input>
        </Col>
        <Col sm={12} md={6} lg={3}>
          <Input
            type="react-select"
            controlId="procedencia"
            name="procedencia"
            label="Procedencia"
            value={procedenciaSeleccionada}
            methodOnChange={handleChange}
            elements={ternaService.getProcedencias}
            nameElementsResponse="elementos"
            propertiesForFormat={{ value: "id", label: "nombre" }}
            setterElementSelect={setProcedenciaSeleccionada}
            config={{ isSearchable: true, isClearable: true, withAsyncDelay: true }}
            relatedFilters={{ titulo: tituloSeleccionado.value }}
            cleanRelatedFiltersRef={cleanRelatedFiltersRef}
            withBusquedaAvanzada={true}
            paramsFilter={{ titulo_busquedaAvanzada: !!inputsForm.titulo_busquedaAvanzada }}
          ></Input>
        </Col>
        <Col sm={12} md={6} lg={3}>
          <Input
            type="react-select"
            controlId="resolucion"
            name="resolucion"
            label="Resolución"
            countCaracters={2}
            value={resolucionSeleccionada}
            methodOnChange={handleChange}
            elements={ternaService.getResolucionesCombo}
            nameElementsResponse="elementos"
            propertiesForFormat={{ value: "id", label: "nombre" }}
            setterElementSelect={setResolucionSeleccionada}
            config={{ isSearchable: true, isClearable: true, withAsyncDelay: true }}
            relatedFilters={{ procedencia: procedenciaSeleccionada.value }}
            paramsFilter={{
              titulo: tituloSeleccionado.value ?? "",
              titulo_busquedaAvanzada: !!inputsForm.titulo_busquedaAvanzada,
              procedencia_busquedaAvanzada: !!inputsForm.procedencia_busquedaAvanzada,
            }}
            cleanRelatedFiltersRef={cleanRelatedFiltersRef}
            withBusquedaAvanzada={true}
          ></Input>
        </Col>
      </Row >
    </Form >
  )
}
