import React, { useEffect } from "react";
import { Alert, Col, Container, Image, Row } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import Home from "./HomeScreen";
import PrivateRoute from "./authentication/PrivateRoute";
import CargosABM from "./cargos/abm/CargosABM";
import CargosABMApproval from "./cargos/abm/CargosABMApproval";
import CargosABMEdit from "./cargos/abm/CargosABMEdit";
import CargosSearch from "./cargos/search/CargosSearch";
import EspecialidadesABM from "./especialidades/abm/EspecialidadesABM";
import EspecialidadesABMApproval from "./especialidades/abm/EspecialidadesABMApproval";
import EspecialidadesABMEdit from "./especialidades/abm/EspecialidadesABMEdit";
import EspecialidadesSearch from "./especialidades/search/EspecialidadesSearch";
import logo from "./images/ba_logo.png";
import UserABM from "./user/abm/UserABM";
import UserABMEdit from "./user/abm/UserABMEdit";
import UserABMSearch from "./user/abm/UserABMSearch";
import Profile from "./user/profile/ProfileScreen";
import { alertConstants, routePaths, typeOfActions } from "./utils/Constants";

import EscuelaABM from "./escuela/abm/EscuelaABM";
import EscuelaABMApproval from "./escuela/abm/EscuelaABMApproval";
import EscuelaABMEdit from "./escuela/abm/EscuelaABMEdit";
import EscuelaSearch from "./escuela/search/EscuelaSearch";

import ApendiceABM from "./apendice/abm/ApendiceABM";

import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { useRef } from "react";
import ApendiceSearch from "./apendice/search/ApendiceSearch";
import AsignaturaABMApproval from "./asignaturas/abm/AsignaturaABMApproval";
import AsignaturaABMEdit from "./asignaturas/abm/AsignaturaABMEdit";
import AsignaturasABM from "./asignaturas/abm/AsignaturasABM";
import AsignaturasSearch from "./asignaturas/search/AsignaturasSearch";
import PrivateComponent from "./authentication/PrivateComponent";
import { DescargasButton } from "./descargas/DescargasButton";
import { DescargasPanel } from "./descargas/DescargasPanel";
import { getTipoIncumbencias } from "./incumbencias/IncumbenciasService";
import { IncumbenciasABM } from "./incumbencias/abm/IncumbenciasABM";
import { IncumbenciasABMEdit } from "./incumbencias/abm/IncumbenciasABMEdit";
import { ClonadorIncumbencias } from "./incumbencias/clonador/ClonadorIncumbencias";
import { IncumbenciasSearch } from "./incumbencias/search/IncumbenciasSearch";
import { DetailByAreaCargo } from "./informes/informeByAreaCargo/DetailByAreaCargo";
import { SearchByAreaCargo } from "./informes/informeByAreaCargo/SearchByAreaCargo";
import { SearchCruzados } from "./informes/informeCruzado/SearchCruzados";
import { DetailIncumByTerna } from "./informes/informeIncumByTerna/DetailIncumByTerna";
import { SearchIncumByTerna } from "./informes/informeIncumByTerna/SearchIncumByTerna";
import { SearchByTerna } from "./informes/informeTernas/SearchByTerna";
import { SearchTopologico } from "./informes/informeTopologico/SearchTopologico";
import { clear, hideOneAlert } from "./store/alerts/alertActions";
import { clearFilters } from "./store/search/searchActions";
import store from "./store/store";
import TernaABMApproval from "./ternas/abm/TernaABMApproval";
import TernaABMEdit from "./ternas/abm/TernaABMEdit";
import { TernaABMTicket } from "./ternas/abm/TernaABMTicket";
import TernasABM from "./ternas/abm/TernasABM";
import TernasSearch from "./ternas/search/TernaSearch";
import { ticketsService } from "./tickets/TicketsService";
import { TicketsSearch } from "./tickets/search/TicketsSearch";
import { ITFSearch } from "./titulos/ITF/search/ITFSearch";
import { EquivalenciaABM } from "./titulos/equivalencias/abm/EquivalenciaABM";
import EquivalenciaSearch from "./titulos/equivalencias/search/EquivalenciaSearch";
import { MenuDashboard } from "./ui/MenuDashboard";
import { menuDashboardOptions } from "./utils/menuDashboardOptions";

import { UnificadorIncumbenciasABM } from "./incumbencias/unificador/abm/UnificadorIncumbenciasABM";
import { UnificadorIncumbenciasSearch } from "./incumbencias/unificador/search/UnificadorIncumbenciasSearch";
import { SincroDash } from "./sincronizacion/SincroDash";
import { SincroAsignaturas } from "./sincronizacion/view/SincroAsignaturas";
import { SincroCargos } from "./sincronizacion/view/SincroCargos";
import { SincroEspecialidades } from "./sincronizacion/view/SincroEspecialidades";
import { SincroIncumbencias } from "./sincronizacion/view/SincroIncumbencias/SincroIncumbencias";
import { SincroTernas } from "./sincronizacion/view/SincroTernas";
import { ITFABM } from "./titulos/ITF/abm/ITFABM";
import { ITFABMApproval } from "./titulos/ITF/abm/ITFABMApproval";
import { ITFABMEdit } from "./titulos/ITF/abm/ITFABMEdit";
import { EquivalenciaABMApproval } from "./titulos/equivalencias/abm/EquivalenciaABMApproval";
import { EquivalenciaABMEdit } from "./titulos/equivalencias/abm/EquivalenciaABMEdit";
import { PERMISOS_MAP } from "./utils/Permisos";
import { UnificadorTernasABM } from "./ternas/unificador/abm/UnificadorTernasABM";
import { UnificadorTernasSearch } from "./ternas/unificador/search/UnificadorTernasSearch";
import { TernasUnificadasSearch } from "./ternas/unificador/search/TernasUnificadasSearch";

const UserApp = (props) => {
  const alert = useSelector((state) => state.alert);

  const { tipoIncumbencias, tipoTickets, modulosTickets, } = useSelector(st => st.data)

  const dispatch = useDispatch();
  const alertRef = useRef();

  useEffect(() => {
    if (alert?.show && alert.scroll) {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [alert])

  useEffect(() => {
    if (!tipoIncumbencias) getTipoIncumbencias();
    if (!tipoTickets) ticketsService.getTipoTickets();
    if (!modulosTickets) ticketsService.getModulosTickets();
  }, [dispatch])

  // const handleClickGenerar = async () => {
  //   try {
  //     dispatch(agregarDescargas([
  //       {
  //         title: "Informe / Topologico",
  //         opcion: OPCIONES_DESCARGAS.TESTEO.COMPLETAR,
  //         filtros: { area: "PRIMARIA", cargo: "PROFESOR DE" },
  //         descripcion: "TODO: ACA IRIA UNA DESCRIPCION DEL ARCHIVO QUE SE ESTA DESCARGANDO.",
  //         fileName: "InformeTopoligicoProfesorDePrimaria.pdf",
  //       },
  //       {
  //         title: "Informe / Incumbencias - Concurrencias por terna",
  //         opcion: OPCIONES_DESCARGAS.TESTEO.CANCELAR,
  //         filtros: { area: "PRIMARIA", cargo: "PROFESOR DE" },
  //         descripcion: "TODO: ACA IRIA UNA DESCRIPCION DEL ARCHIVO QUE SE ESTA DESCARGANDO.",
  //         fileName: "InformeIncumbenciasProfesorDePrimaria.pdf",
  //       }
  //     ]));
  //   } catch (err) {
  //     dispatch(error(err.message))
  //   }
  // }

  return (
    <>
      <div className="d-flex flex-column flex-grow-1">
        <Navbar expand="md">
          <Container className="position-relative align-items-center">
            <Navbar.Brand as={Link} to={routePaths.HOME}>
              <Image src={logo} alt="Anexo títulos" />
              <h5 /* onClick={handleClickGenerar} */ >Anexo títulos</h5>
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse >
              <Nav className="d-md-flex justify-content-end flex-grow-1">
                {menuDashboardOptions.map((currentElement, index) => (currentElement.menuTitle) ?
                  <PrivateComponent key={`Nav${currentElement.title}-${index}`} permisos={currentElement.permisoRequired}>
                    <NavDropdown title={currentElement.title} className="ml-md-4">
                      {currentElement.options.map((element, subIndex) => (
                        <PrivateComponent key={`NavLink${element.title}-${index}-${subIndex}`} permisos={element.permisoRequired}>
                          <NavDropdown.Item as={Link} to={element.path} key={element.title} onClick={() => dispatch(clearFilters())}>
                            {element.title}
                          </NavDropdown.Item>
                        </PrivateComponent>
                      ))}
                    </NavDropdown>
                  </PrivateComponent>
                  :
                  <PrivateComponent key={"Nav" + currentElement.title + index} permisos={currentElement.permisoRequired}>
                    <Link
                      to={currentElement.path}
                      onClick={() => dispatch(clearFilters())}
                      className="ml-md-4"
                    >{currentElement.title}</Link>
                  </PrivateComponent>
                )}

                {/* </div> */}

                {/* TODO: REPETIDO EN EL MENU "OTROS" VERIFICAR SI DEJARLO SOLO EN EL MENU O SACARLO DEL MENU */}
                {/* {AuthService.validatePermiso(PERMISOS_MAP.USUARIOS_ACCESO) && <Link
                  to={routePaths.ABM_USER_SEARCH}
                  onClick={() => dispatch(clearFilters())}
                >
                  Usuarios
                </Link>}
                 */}

                <div className="nav-userContent">
                  <NavDropdown className="ml-md-4" title={<span className={'nameAndSurName'}>{props.nameAndSurName}</span>}
                    icon={faUserCircle}>
                    <Link to={routePaths.PROFILE}>Ir al perfil</Link>
                    <NavDropdown.Divider />
                    <Link
                      to="/"
                      onClick={() => {
                        dispatch(clear());
                        props.logOut();
                      }}
                      className="text-danger"
                    >
                      Cerrar Sesi&oacute;n
                    </Link>
                  </NavDropdown>
                </div>

                <NavDropdown className="descargas__navdropdown" title={<DescargasButton />}>
                  <DescargasPanel />
                </NavDropdown>

              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <div className="col-fluid mt-3 d-flex flex-column flex-grow-1 position-relative">
          <Row className="container mx-auto">
            <Col ref={alertRef}>
              {alert.show && (
                alert.type == alertConstants.MULTIPLE ?
                  alert.message.map((currentElement, index) =>
                    <> {currentElement.show && <Alert
                      variant={currentElement.alert.type}
                      onClose={() => { store.dispatch(hideOneAlert(index)) }}
                      dismissible
                    >
                      {currentElement.alert.message}
                    </Alert>}</>)
                  :
                  <Alert
                    variant={alert.type}
                    dismissible
                    onClose={() => store.dispatch(clear())}
                  >
                    {alert.message}
                  </Alert>
              )}
            </Col>
          </Row>
          <Switch>
            {/* ASIGNATURAS */}
            <PrivateRoute exact
              path={routePaths.ABM_ASIGNATURAS_SEARCH}
            // permisos={PERMISOS_MAP.MODULO_ASIGNATURA}
            >
              <AsignaturasSearch></AsignaturasSearch>
            </PrivateRoute>

            <PrivateRoute exact
              path={routePaths.ABM_ASIGNATURAS_CREATE}
              permisos={PERMISOS_MAP.ASIGNATURA_CREAR}
            >
              <AsignaturasABM></AsignaturasABM>
            </PrivateRoute>
            <PrivateRoute exact
              path={routePaths.ABM_ASIGNATURAS_EDIT}
              permisos={PERMISOS_MAP.ASIGNATURA_EDITAR}
            >
              <AsignaturaABMEdit></AsignaturaABMEdit>
            </PrivateRoute>
            <PrivateRoute exact
              path={routePaths.ABM_ASIGNATURAS_APPROVAL}
              permisos={PERMISOS_MAP.ASIGNATURA_APROBAR}
            >
              <AsignaturaABMApproval></AsignaturaABMApproval>
            </PrivateRoute>

            {/* ESPECIALIDAD */}
            <PrivateRoute exact
              path={routePaths.ABM_ESPECIALIDADES_SEARCH}
            // permisos={PERMISOS_MAP.MODULO_ESPECIALIDAD}
            >
              <EspecialidadesSearch></EspecialidadesSearch>
            </PrivateRoute>

            <PrivateRoute exact
              path={routePaths.ABM_ESPECIALIDADES_EDIT}
              permisos={PERMISOS_MAP.ESPECIALIDAD_EDITAR}
            >
              <EspecialidadesABMEdit></EspecialidadesABMEdit>
            </PrivateRoute>

            <PrivateRoute exact
              path={routePaths.ABM_ESPECIALIDADES_APPROVAL}
              permisos={PERMISOS_MAP.ESPECIALIDAD_APROBAR}
            >
              <EspecialidadesABMApproval></EspecialidadesABMApproval>
            </PrivateRoute>

            <PrivateRoute exact
              path={routePaths.ABM_ESPECIALIDADES_CREATE}
              permisos={PERMISOS_MAP.ESPECIALIDAD_CREAR}
            >
              <EspecialidadesABM></EspecialidadesABM>
            </PrivateRoute>

            {/* TIPO DE ESCUELA */}
            <PrivateRoute exact
              path={routePaths.ABM_ESCUELA_SEARCH}
            // permisos={PERMISOS_MAP.MODULO_TIPO_ESCUELA}
            >
              <EscuelaSearch></EscuelaSearch>
            </PrivateRoute>

            <PrivateRoute
              path={routePaths.ABM_ESCUELA_CREATE}
              permisos={PERMISOS_MAP.TIPO_ESCUELA_CREAR}
            >
              <EscuelaABM></EscuelaABM>
            </PrivateRoute>

            <PrivateRoute exact
              path={routePaths.ABM_ESCUELA_EDIT}
              permisos={PERMISOS_MAP.TIPO_ESCUELA_EDITAR}
            >
              <EscuelaABMEdit></EscuelaABMEdit>
            </PrivateRoute>

            <PrivateRoute exact
              path={routePaths.ABM_ESCUELA_APPROVAL}
              permisos={PERMISOS_MAP.TIPO_ESCUELA_APROBAR}
            >
              <EscuelaABMApproval></EscuelaABMApproval>
            </PrivateRoute>

            {/* APENDICE */}
            <PrivateRoute exact
              path={routePaths.ABM_APENDICE_SEARCH}
            // permisos={PERMISOS_MAP.MODULO_APENDICE}
            >
              <ApendiceSearch></ApendiceSearch>
            </PrivateRoute>

            <PrivateRoute
              path={routePaths.ABM_APENDICE_CREATE}
              permisos={PERMISOS_MAP.APENDICE_CREAR}
            >
              <ApendiceABM></ApendiceABM>
            </PrivateRoute>

            {/* TERNAS */}
            <PrivateRoute exact
              path={routePaths.ABM_TERNA_SEARCH}
            // permisos={PERMISOS_MAP.MODULO_TERNA}
            >
              <TernasSearch></TernasSearch>
            </PrivateRoute>

            <PrivateRoute exact
              path={routePaths.ABM_TERNA_CREATE}
              permisos={PERMISOS_MAP.TERNA_CREAR}
            >
              <TernasABM></TernasABM>
            </PrivateRoute>

            <PrivateRoute exact
              path={routePaths.ABM_TERNA_EDIT}
              permisos={PERMISOS_MAP.TERNA_EDITAR}
            >
              <TernaABMEdit></TernaABMEdit>
            </PrivateRoute>

            <PrivateRoute exact
              path={routePaths.ABM_TERNA_APPROVAL}
              permisos={PERMISOS_MAP.TERNA_APROBAR}
            >
              <TernaABMApproval></TernaABMApproval>
            </PrivateRoute>

            {/* EQUIVALENTES */}
            <PrivateRoute
              exact
              path={routePaths.ABM_TITULOS_EQUIVALENTES_SEARCH}
            // permisos={PERMISOS_MAP.MODULO_TITULO_EQUIVALENTE}
            >
              <EquivalenciaSearch />
            </PrivateRoute>

            <PrivateRoute
              exact
              path={routePaths.ABM_TITULOS_EQUIVALENTES_CREATE}
              permisos={PERMISOS_MAP.TITULO_EQUIVALENTE_CREAR}
            >
              <EquivalenciaABM typeOfAction={typeOfActions.CREATE} />
            </PrivateRoute>

            <PrivateRoute
              exact
              path={routePaths.ABM_TITULOS_EQUIVALENTES_EDIT}
              permisos={PERMISOS_MAP.TITULO_EQUIVALENTE_EDITAR}
            >
              <EquivalenciaABMEdit />
            </PrivateRoute>

            <PrivateRoute
              exact
              path={routePaths.ABM_TITULOS_EQUIVALENTES_APPROVAL}
              permisos={PERMISOS_MAP.TITULO_EQUIVALENTE_APROBAR}
            >
              <EquivalenciaABMApproval />
            </PrivateRoute>

            {/* TITULOS ITF */}
            <PrivateRoute
              exact
              path={routePaths.ABM_TITULOS_ITF_SEARCH}
            // permisos={PERMISOS_MAP.MODULO_TITULO_ITF}
            >
              <ITFSearch />
            </PrivateRoute>

            <PrivateRoute
              exact
              path={routePaths.ABM_TITULOS_ITF_CREATE}
              permisos={PERMISOS_MAP.TITULO_ITF_CREAR}
            >
              <ITFABM typeOfAction={typeOfActions.CREATE} />
            </PrivateRoute>

            <PrivateRoute
              exact
              path={routePaths.ABM_TITULOS_ITF_EDIT}
              permisos={PERMISOS_MAP.TITULO_ITF_EDITAR}
            >
              <ITFABMEdit />
            </PrivateRoute>

            <PrivateRoute
              exact
              path={routePaths.ABM_TITULOS_ITF_APPROVAL}
              permisos={PERMISOS_MAP.TITULO_ITF_APROBAR}
            >
              <ITFABMApproval />
            </PrivateRoute>

















            <PrivateRoute exact
              path={routePaths.ABM_TERNA_TICKET}
            >
              <TernaABMTicket></TernaABMTicket>
            </PrivateRoute>






















            {/* ABM CARGOS */}
            <PrivateRoute exact
              path={routePaths.ABM_CARGOS_SEARCH}
              permisos={PERMISOS_MAP.VER_MODULO_CARGO}>
              <CargosSearch></CargosSearch>
            </PrivateRoute>
            <PrivateRoute
              path={routePaths.ABM_CARGOS_CREATE}
              permisos={PERMISOS_MAP.CARGO_CREAR}>
              <CargosABM></CargosABM>
            </PrivateRoute>
            <PrivateRoute exact path={routePaths.ABM_CARGOS_EDIT}
              permisos={PERMISOS_MAP.CARGO_EDITAR}>
              <CargosABMEdit></CargosABMEdit>
            </PrivateRoute>
            <PrivateRoute exact
              path={routePaths.ABM_CARGOS_APPROVAL}
              permisos={PERMISOS_MAP.CARGO_APROBAR}>
              <CargosABMApproval></CargosABMApproval>
            </PrivateRoute>


            {/* ABM USUARIO */}
            <PrivateRoute exact path={routePaths.ABM_USER_SEARCH} permisos={PERMISOS_MAP.VER_MODULO_USUARIO}>
              <UserABMSearch></UserABMSearch>
            </PrivateRoute>

            <PrivateRoute exact path={routePaths.ABM_USER_EDIT} permisos={PERMISOS_MAP.USUARIO_EDITAR}>
              <UserABMEdit></UserABMEdit>
            </PrivateRoute>

            <Route path={routePaths.ABM_USER_CREATE} permisos={PERMISOS_MAP.USUARIO_CREAR}>
              <UserABM></UserABM>
            </Route>
            {/* *********** */}


            {/* SINCRO */}
            <PrivateRoute exact path={routePaths.SINCRO_PANEL} permisos={PERMISOS_MAP.VER_MODULO_SINCRONIZACION}>
              <SincroDash />
            </PrivateRoute>

            <PrivateRoute exact path={routePaths.SINCRO_PANEL_TERNAS} permisos={PERMISOS_MAP.VER_MODULO_SINCRONIZACION}>
              <SincroTernas />
            </PrivateRoute>

            <PrivateRoute exact path={routePaths.SINCRO_PANEL_ESPECIALIDADES} permisos={PERMISOS_MAP.VER_MODULO_SINCRONIZACION}>
              <SincroEspecialidades />
            </PrivateRoute>

            <PrivateRoute exact path={routePaths.SINCRO_PANEL_CARGOS} permisos={PERMISOS_MAP.VER_MODULO_SINCRONIZACION}>
              <SincroCargos />
            </PrivateRoute>

            <PrivateRoute exact path={routePaths.SINCRO_PANEL_ASIGNATURAS} permisos={PERMISOS_MAP.VER_MODULO_SINCRONIZACION}>
              <SincroAsignaturas />
            </PrivateRoute>

            <PrivateRoute exact path={routePaths.SINCRO_PANEL_INCUMBENCIAS} permisos={PERMISOS_MAP.VER_MODULO_SINCRONIZACION}>
              <SincroIncumbencias />
            </PrivateRoute>
            {/* *********** */}









            {/* *********** */}

            {/* INFORMES */}
            <PrivateRoute exact path={routePaths.INFORMES_SEARCH_TERNAS}>
              <SearchByTerna></SearchByTerna>
            </PrivateRoute>

            <PrivateRoute exact path={routePaths.INFORMES_SEARCH_INCUMBENCIAS_BY_TERNA}>
              <SearchIncumByTerna></SearchIncumByTerna>
            </PrivateRoute>

            <PrivateRoute exact path={routePaths.INFORMES_SEARCH_TERNAS_BY_AREA_CARGO}>
              <SearchByAreaCargo></SearchByAreaCargo>
            </PrivateRoute>

            <PrivateRoute exact path={routePaths.INFORMES_SEARCH_CRUZADO}>
              <SearchCruzados></SearchCruzados>
            </PrivateRoute>

            <PrivateRoute exact path={routePaths.INFORMES_SEARCH_TOPOLOGICO}>
              <SearchTopologico></SearchTopologico>
            </PrivateRoute>

            <PrivateRoute exact path={routePaths.INFORMES_SEARCH_INCUMBENCIAS_BY_TERNA_DETAIL}>
              <DetailIncumByTerna></DetailIncumByTerna>
            </PrivateRoute>

            <PrivateRoute exact path={routePaths.INFORMES_SEARCH_TERNAS_BY_AREA_CARGO_DETAIL}>
              <DetailByAreaCargo></DetailByAreaCargo>
            </PrivateRoute>

            {/* *********** */}

            {/* ABM ASIGNATURAS */}

            {/* *********** */}

            {/* ABM TERNAS */}

            <Route exact path={routePaths.ABM_TERNA_UNIFICADOR_SEARCH}>
              <UnificadorTernasSearch />
            </Route>

            <Route exact path={routePaths.ABM_TERNAS_UNIFICADAS_SEARCH}>
              <TernasUnificadasSearch />
            </Route>

            <Route exact path={routePaths.ABM_TERNA_UNIFICADOR_CREATE}>
              <UnificadorTernasABM />
            </Route>






            {/* ******INCUMBENCIAS****** */}

            {/* 
            //TODO: VER LA PARTE DE LOS PERMISOS -> MIENTRAS MANDO PUBLICO
            */}
            <Route exact path={routePaths.ABM_INCUMBENCIAS_CLONADOR + "/:cloneType"}>
              <ClonadorIncumbencias />
            </Route>

            <Route exact path={routePaths.ABM_INCUMBENCIAS_UNIFICADOR_SEARCH}>
              <UnificadorIncumbenciasSearch />
            </Route>

            <Route exact path={routePaths.ABM_INCUMBENCIAS_UNIFICADOR_CREATE}>
              <UnificadorIncumbenciasABM />
            </Route>

            <Route exact path={routePaths.ABM_INCUMBENCIAS_CREATE + "/:mainElement"}>
              <IncumbenciasABM />
            </Route>

            <Route exact path={routePaths.ABM_INCUMBENCIAS_SEARCH + "/:typeOfSearch"}>
              <IncumbenciasSearch />
            </Route>

            <Route exact path={routePaths.ABM_INCUMBENCIAS_EDIT + "/:mainElement"}>
              <IncumbenciasABMEdit />
            </Route>

            {/* ******TICKETS****** */}
            <Route exact path={routePaths.ABM_TICKETS_SEARCH + "/:typeOfSearch"}>
              <TicketsSearch />
            </Route>

            {/* ******MENU-DASHBOARD***** */}
            {
              menuDashboardOptions.filter(currentMenu => currentMenu.options).map((currentElement, index) =>
                <PrivateRoute key={"Route" + currentElement.title + index} exact path={currentElement.path} permisos={currentElement.permisoRequired}>
                  <MenuDashboard menu={currentElement} />
                </PrivateRoute>)
            }

            <Route exact path={routePaths.PROFILE}>
              <Profile></Profile>
            </Route>
            <Route exact path="/">
              <Home></Home>
            </Route>
            <Redirect to="/" />
          </Switch>
        </div>
      </div>{" "}
    </ >
  );
};

export default UserApp;