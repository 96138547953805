import React, { useEffect, useRef, useState } from 'react';
import { Alert, Badge, Col, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Paginacion from '../../generics/Paginacion';
import Dialog from '../../generics/dialog';
import { adaptGetIncumbenciasByTernaToInforme2 } from '../../incumbencias/IncumbenciasAdapter';
import { getIncumbenciasByTerna } from '../../incumbencias/IncumbenciasService';
import { TrEnConcurrenciaCon } from '../../incumbencias/details/ternas/TrEnConcurrenciaCon';
import { error } from '../../store/alerts/alertActions';
import { OPCIONES_DESCARGAS, agregarDescargas } from '../../store/descargas/descargasActions.';
import { CustomTooltip } from '../../ui/CustomTooltip';
import { Loader } from '../../ui/Loader';
import { ScreenWithFabButtons } from '../../ui/ScreenWithFabButtons';
import { estadosConstans, routePaths, tooltipsText } from '../../utils/Constants';

import * as especialidadesService from "../../especialidades/EspecialidadesService";

import { cleanFab, searchFab } from '../../store/screen/screenActions';
import { getTitulosITFFromTerna } from '../../titulos/ITF/ITFService';
import { getEquivalenciasFromTerna } from '../../titulos/equivalencias/equivalenciaService';
import Input from '../../ui/Input';
import { DetailModalIncumByTerna } from './DetailModalIncumByTerna';
import { RelacionadosInfoModal, RelacionadosRow, RelacionadosTable } from './RelacionadosInfoModal';
import { handlerChange } from '../../utils/commons';
import CheckBoxEstados from '../../generics/CheckBoxEstados';
import PrivateComponent from '../../authentication/PrivateComponent';
import { PERMISOS_MAP } from '../../utils/Permisos';

const THead = () => {
    return <tr>
        {/* <th>Área</th> */}
        <th>Cargo</th>
        <th>Asignatura</th>
        <th>Especialidad</th>
        <th>Apendice</th>
        <th>Folio</th>
        <th>Pts</th>
        <th>Calidad</th>
        <th>Ref. Baja</th>
        <th>Fecha creación</th>
        <th>Estado</th>
        <th></th>
    </tr>
}

const FormRow = ({ element }) => {
    return <>
        <tr>
            {/* <td>{element.area.nombre}</td> */}
            <td>{element.cargo.nombre}</td>
            <td>{element.asignatura?.nombre ?? "SIN ASIGNATURA"}</td>
            <td>{element.especialidad.nombre}</td>
            <td>{element.apendice.nombre}</td>
            <td>{element.folio}</td>
            <td>{element.puntaje}</td>
            <td>{element.calidad}</td>
            <td>{element.ref_baja ?? "-"}</td>
            <td>{element.createdAt}</td>
            <td>
                {element.estado ?
                    <Badge variant={element.estado.color}>
                        {element.estado.nombre}
                    </Badge>
                    :
                    "-"
                }
            </td>
            <td>
                <CustomTooltip text={tooltipsText.DETALLE}>
                    <DetailModalIncumByTerna incumbencia={element} />
                </CustomTooltip>
            </td>
        </tr>

        {element.concurrencia &&
            <TrEnConcurrenciaCon data={element} colSpan={10} />
        }
    </>
}

const IncumbenciasInfoSection = (incumbenciasInfo = {}) => {
    return <div className="ml-5">
        <h6 className="width-max-content">
            Docente: {incumbenciasInfo["Docente"]} | Habilitante: {incumbenciasInfo["Habilitante"]} | Supletorio: {incumbenciasInfo["Supletorio"]} | DyM: {incumbenciasInfo["Doctorados y Maestrías"]}
        </h6>
    </div>
}

export const agruparIncumbenciasPorAreaEscuela = (arrIncumbencias) => {
    let incumbenciasAgrupadas = []
    arrIncumbencias.forEach(ctIncumb => {
        const grupoIndex = incumbenciasAgrupadas.findIndex(ctGrupo => ctGrupo.area.id == ctIncumb.area.id && ctGrupo.tipoEscuela.id == ctIncumb.tipoEscuela.id)
        if (grupoIndex >= 0) {
            incumbenciasAgrupadas[grupoIndex].elementos.push(ctIncumb)
        } else {
            incumbenciasAgrupadas.push({ area: ctIncumb.area, tipoEscuela: ctIncumb.tipoEscuela, elementos: [ctIncumb] })
        }
    })
    return incumbenciasAgrupadas
}

export const DetailIncumByTerna = () => {
    const [terna, setTerna] = useState()
    const [areaSeleccionada, setAreaSeleccionada] = useState()

    const defaultInputsForm = {
        estado: [estadosConstans.APROBADO, estadosConstans.PUBLICADO, estadosConstans.MIGRADO]
    }

    const [inputsForm, setInputsForm] = useState(defaultInputsForm);

    const screenState = useSelector(st => st.screen);

    const [gruposIncumbencias, setGruposIncumbencias] = useState()
    const [incumbenciasInfo, setIncumbenciasInfo] = useState()

    const [arrEquivalencias, setArrEquivalencias] = useState([])
    const [cantidadEquivalencias, setCantidadEquivalencias] = useState()
    const [pageEquivalencias, setPageEquivalencias] = useState(1)
    const [selectedEquivalencias, setSelectedEquivalencias] = useState([])

    const [resetChecks, setResetChecks] = useState(false);

    const [arrITFs, setArrITFs] = useState([])
    const [cantidadITFs, setCantidadITFs] = useState()
    const [pageITFs, setPageITFs] = useState(1)
    const [selectedITFs, setSelectedITFs] = useState([])

    const [cantidad, setCantidad] = useState();
    const [page, setPage] = useState(1);

    const [loading, setLoading] = useState();

    const history = useHistory()
    const location = useLocation();
    const dispatch = useDispatch();

    const cantidadMaximaElementos = 1000;
    const generarEnFormatoPDF = cantidad <= cantidadMaximaElementos

    const isMountedRef = useRef(true);

    const siglasInforme = "IdT"

    const searchIncumbenciasByTernas = async (filtros = {}, manageIsLoading = true) => {
        if (manageIsLoading) setLoading(true);
        let ctfiltros = {
            terna_id: location.state.terna.id,
            limit: 10,
            page: page,
            searchAllTernas: true,
            ...inputsForm,
            ...filtros,
        }
        if (location?.state?.area) filtros["area"] = location.state.area

        try {
            const resp = await getIncumbenciasByTerna(ctfiltros, isMountedRef);
            const elementos = adaptGetIncumbenciasByTernaToInforme2(resp.elementos);
            const incumbenciasAgrupadas = agruparIncumbenciasPorAreaEscuela(elementos)
            setPage(resp.page)
            if (manageIsLoading) setLoading(false);
            return { ...resp, elementos: incumbenciasAgrupadas };
        } catch (err) {
            console.log(err)
            throw new Error(err);
        }
    }

    const loadIncumbenciasData = async (filtros = {}) => {
        try {
            const resp = await searchIncumbenciasByTernas({ page: 1, ...filtros }, false);
            setGruposIncumbencias(resp.elementos)
            setIncumbenciasInfo(resp.info)
            setCantidad(resp.cantidad)
        } catch (err) {
        }
    }

    const loadEquivalenciasData = async (currentTerna, page = 1) => {
        try {
            const filtros = {
                terna_id: currentTerna.id,
                limit: 5,
                page
            }

            const resp = await getEquivalenciasFromTerna(filtros, isMountedRef)
            setCantidadEquivalencias(resp.cantidad);
            setArrEquivalencias(resp.elementos);
            setPageEquivalencias(resp.page);
        } catch (err) {
            dispatch(error("No se pudieron cargar los titulos equivalentes relacionados"))
        }
    }

    const loadITFsData = async (currentTerna, page = 1) => {
        try {
            const filtros = {
                terna_id: currentTerna.id,
                limit: 5,
                page,
            }

            const resp = await getTitulosITFFromTerna(filtros, isMountedRef)
            setCantidadITFs(resp.cantidad)
            setArrITFs(resp.elementos)
            setPageITFs(resp.page)
        } catch (err) {
            dispatch(error("No se pudieron cargar los titulos ITFs relacionados"))
        }
    }

    const handleChangePage = async (event, value) => {
        const resp = await searchIncumbenciasByTernas({ page: value });
        setGruposIncumbencias(resp.elementos);
    }

    const handleClickGenerarPDF = async ({ sin_concurrencias }) => {
        const arrEquivalenciasIds = selectedEquivalencias.map(ctRelacion => ctRelacion.terna.id)
        const arrITFIds = selectedITFs.map(ctRelacion => ctRelacion.terna.id)
        try {
            let filtros = {
                terna_id: terna.id,
                searchAllTernas: true,
                sin_concurrencias,
                ...inputsForm,
            }
            const fileName = `${terna.titulo.nombre}-${terna.procedencia.nombre}-${terna.resolucion.nombre}-${siglasInforme}`;

            if (arrEquivalenciasIds.length > 0) filtros.ternas_equivalentes_id = arrEquivalenciasIds;
            if (arrITFIds.length > 0) filtros.ternas_itfs_id = arrITFIds;

            console.log(2, { fileName })

            dispatch(agregarDescargas([{
                title: "Informe / Incumbencias - Concurrencias por terna / Detalle (PDF)",
                opcion:
                    sin_concurrencias ?
                        OPCIONES_DESCARGAS.PDF.INFORME_INCUMBENCIAS_BY_TERNA_SEGUNDA_VISTA_SIN_CONCURRENCIAS
                        :
                        OPCIONES_DESCARGAS.PDF.INFORME_INCUMBENCIAS_BY_TERNA_SEGUNDA_VISTA,
                filtros: filtros,
                descripcion: "TODO: ACA IRIA UNA DESCRIPCION DEL ARCHIVO QUE SE ESTA DESCARGANDO.",
                fileName
            }]));
        } catch (err) {
            dispatch(error(err.message))
        }
    }

    const handleClickGenerarCSV = async () => {
        const arrEquivalenciasIds = selectedEquivalencias.map(ctRelacion => ctRelacion.terna.id)
        const arrITFIds = selectedITFs.map(ctRelacion => ctRelacion.terna.id)

        try {
            let filtros = {
                terna_id: terna.id,
                searchAllTernas: true,
                ...inputsForm,
            }
            const fileName = `${terna.titulo.nombre}-${terna.procedencia.nombre}-${terna.resolucion.nombre}-${siglasInforme}`;

            if (arrEquivalenciasIds.length > 0) filtros.ternas_equivalentes_id = arrEquivalenciasIds;
            if (arrITFIds.length > 0) filtros.ternas_itfs_id = arrITFIds;

            console.log(1, { fileName })

            dispatch(agregarDescargas([{
                title: "Informe / Incumbencias - Concurrencias por terna / Detalle (CSV)",
                opcion: OPCIONES_DESCARGAS.CSV.INFORME_INCUMBENCIAS_BY_TERNA_SEGUNDA_VISTA,
                filtros: filtros,
                descripcion: "TODO: ACA IRIA UNA DESCRIPCION DEL ARCHIVO QUE SE ESTA DESCARGANDO.",
                fileName
            }]));
        } catch (err) {
            dispatch(error(err.message))
        }
    }

    const handleClickGenerarInformativo = async () => {
        const arrEquivalenciasIds = selectedEquivalencias.map(ctRelacion => ctRelacion.terna.id)
        const arrITFIds = selectedITFs.map(ctRelacion => ctRelacion.terna.id)

        try {
            const filtros = {
                terna_id: terna.id,
                informativo: true,
                searchAllTernas: true,
                ...inputsForm,
            }

            const fileName = `${terna.titulo.nombre}-${terna.procedencia.nombre}-${terna.resolucion.nombre}-${siglasInforme} Inf`;

            if (arrEquivalenciasIds.length > 0) filtros.ternas_equivalentes_id = arrEquivalenciasIds;
            if (arrITFIds.length > 0) filtros.ternas_itfs_id = arrITFIds;

            console.log(3, { fileName })

            dispatch(agregarDescargas([{
                title: "Informe / Incumbencias - Concurrencias por terna / Detalle Informativo (PDF)",
                opcion: OPCIONES_DESCARGAS.PDF.INFORME_INCUMBENCIAS_BY_TERNA_SEGUNDA_VISTA_INFORMATIVO,
                filtros: filtros,
                descripcion: "TODO: ACA IRIA UNA DESCRIPCION DEL ARCHIVO QUE SE ESTA DESCARGANDO.",
                fileName
            }]));
        } catch (err) {
            dispatch(error(err.message))
        }
    }

    const changeSelectedEquivalencia = (modalChecksState) => {
        setSelectedEquivalencias(modalChecksState)
    }

    const changeSelectedITF = (modalChecksState) => {
        setSelectedITFs(modalChecksState)
    }

    const onClickDelete = (relacion, setter) => {
        setter(st => st.filter(ctRelacion => ctRelacion.id != relacion.id))
    }

    const handleChange = (e) => {
        console.log("ACA9", { inputsForm, e })
        handlerChange(setInputsForm, inputsForm, null, e)
    };

    useEffect(() => {
        const currentTerna = location.state?.terna;
        const area = location.state?.area;
        const areaSeleccionada = location.state?.areaSeleccionada;
        if (currentTerna) {
            setTerna(currentTerna);
            let initialFiltros = { ...defaultInputsForm, terna_id: currentTerna?.id, }
            if (area) {
                if (areaSeleccionada) setAreaSeleccionada(areaSeleccionada);
                initialFiltros.area_id = area
            }
            setInputsForm(initialFiltros)
            setLoading(true)
            Promise.all([
                loadIncumbenciasData(initialFiltros),
                loadEquivalenciasData(currentTerna),
                loadITFsData(currentTerna),
            ]).then(resp => setLoading(false))
        } else {
            console.log("Se recargo navegador / entro x URL -> return a searchByTerna")
            history.push(routePaths.INFORMES_SEARCH_INCUMBENCIAS_BY_TERNA)
        }
        return () => { isMountedRef.current = false }
    }, [location])

    const resetForm = () => {
        console.log("RESET FORM")
    }

    const handleSearch = async () => {
        try {
            setLoading(true)
            await loadIncumbenciasData();
            setLoading(false)
        }
        catch (err) {
            dispatch(error("Error al realizar la busqueda"))
        }
    }

    useEffect(() => {
        if (screenState.clean) {
            resetForm()
            dispatch(cleanFab(false));
        }

        if (screenState.search) {
            handleSearch()
            dispatch(searchFab(false));
        }
    }, [screenState])

    useEffect(() => {
        console.log("ACA", terna)
    }, [terna])
    return (
        <ScreenWithFabButtons
            returnState={location.state?.returnData}
            hideSearchButton={false}
            // hideCleanButton={false}
            searchButtonDisabled={loading}
        // cleanButtonDisabled={loading}
        >
            <Row>
                <Col>
                    <h1>Informes</h1>
                    {terna &&
                        <div className="d-flex mb-5">
                            <div className="d-flex flex-column flex-grow-1">
                                <h4>{terna.titulo.nombre} | {terna.procedencia.nombre} | {terna.resolucion.nombre}</h4>
                                <div className="d-flex">
                                    <RelacionadosInfoModal
                                        ternaId={terna.id}
                                        selectedRelacionados={selectedEquivalencias}
                                        cantidadRelacionados={cantidadEquivalencias}
                                        arrRelacionados={arrEquivalencias}
                                        page={pageEquivalencias}
                                        changeSelected={changeSelectedEquivalencia}
                                        loadData={(page) => loadEquivalenciasData(terna, page)}
                                        nombreRelacion="equivalencia"
                                    />
                                    <div className="ml-4"></div>
                                    <RelacionadosInfoModal
                                        ternaId={terna.id}
                                        selectedRelacionados={selectedITFs}
                                        cantidadRelacionados={cantidadITFs}
                                        arrRelacionados={arrITFs}
                                        page={pageITFs}
                                        changeSelected={changeSelectedITF}
                                        loadData={(page) => loadITFsData(terna, page)}
                                        nombreRelacion="ITF"
                                    />
                                </div>

                                {terna.nota && (
                                    <div>
                                        <p className="mb-0 mt-2"><b>Nota: </b>{terna.nota}</p>
                                    </div>
                                )}

                                {terna.regla && (
                                    <div>
                                        <p className="mb-0 mt-2"><b>Regla: </b>{terna.regla}</p>
                                    </div>
                                )}

                            </div>
                            {!loading && gruposIncumbencias?.length > 0 &&
                                IncumbenciasInfoSection(incumbenciasInfo)
                            }
                        </div>}
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={6} lg={4}>
                    {terna &&
                        <Input
                            type="react-select"
                            controlId="area_id"
                            name="area_id"
                            label="Área"
                            value={areaSeleccionada}
                            methodOnChange={handleChange}
                            elements={especialidadesService.getAreas}
                            nameElementsResponse="data"
                            propertiesForFormat={{ value: "id", label: "nombre" }}
                            setterElementSelect={setAreaSeleccionada}
                            paramsFilter={{ areas_from_incumbencias_with_terna_id: terna?.id }}
                            config={{ isSearchable: true, isClearable: true }}
                        ></Input>
                    }
                </Col>

                <Col sm={12} md={12} lg={6}>
                    <CheckBoxEstados
                        inputsForm={inputsForm}
                        setInputsForm={setInputsForm}
                        resetChecks={resetChecks}
                        setResetChecks={setResetChecks}
                    />
                </Col>
            </Row>
            {!loading && <Row>
                <Col>
                    {gruposIncumbencias?.length > 0 ?
                        <>
                            {gruposIncumbencias.map(ctGrupo =>
                                <React.Fragment key={"AreasAgrupadas-" + ctGrupo.id}>
                                    <h3 style={{ lineHeight: "1em !important", marginBottom: 0, marginTop: "1rem", color: "#404041" }}>
                                        {ctGrupo.area.romano} - {ctGrupo.area.nombre}
                                    </h3>
                                    <h3>
                                        {ctGrupo.tipoEscuela.id} - {ctGrupo.tipoEscuela.nombre}
                                    </h3>
                                    <Table responsive>
                                        <thead>
                                            <THead />
                                        </thead>
                                        <tbody>
                                            {ctGrupo.elementos.map((ctIncumb, index) =>
                                                <React.Fragment key={`IncumbenciaRowInforme-${ctGrupo.id}-${index}`}>
                                                    <FormRow element={ctIncumb} />
                                                </React.Fragment>
                                            )}
                                        </tbody>
                                    </Table>
                                </React.Fragment>
                            )}

                            <Paginacion
                                totalItems={cantidad}
                                currentPage={page}
                                onChangePage={handleChangePage}
                            />
                        </>
                        :
                        <Alert variant="info">Esta terna no tiene incumbencias asignadas.</Alert>
                    }

                    {selectedEquivalencias.length > 0 &&
                        <div className="mt-5 mb-5">
                            <h3>Se agregaran al informe las equivalencias:</h3>
                            <RelacionadosTable hideAddToInforme hidePagination>
                                {selectedEquivalencias.map(ctSelected =>
                                    <React.Fragment key={`EquivalenciaRow-${ctSelected.id}`}>
                                        <RelacionadosRow
                                            relacionId={ctSelected.id}
                                            terna={ctSelected.terna}
                                            onClickDelete={() => onClickDelete(ctSelected, setSelectedEquivalencias)}
                                        />
                                    </React.Fragment>
                                )}
                            </RelacionadosTable>
                        </div>
                    }

                    {selectedITFs.length > 0 &&
                        <div class="mt-5 mb-5">
                            <h3>Se agregaran al informe los titulos ITFs:</h3>
                            <RelacionadosTable hideAddToInforme hidePagination>
                                {selectedITFs.map(ctSelected =>
                                    <React.Fragment key={`ITFRow-${ctSelected.id}`}>
                                        <RelacionadosRow
                                            relacionId={ctSelected.id}
                                            terna={ctSelected.terna}
                                            onClickDelete={() => onClickDelete(ctSelected, setSelectedITFs)}
                                        />
                                    </React.Fragment>
                                )}
                            </RelacionadosTable>
                        </div>
                    }
                    <PrivateComponent permisos={PERMISOS_MAP.EXPORTAR_INFORME}>
                        <Dialog
                            preventFocus
                            action={handleClickGenerarCSV}
                            title={"Informe Total (.CSV)"}
                            variant="primary"
                            size="md"
                            btnConfirmText="Generar"
                            btnVariant="primary"
                            btnText="Informe Total (.CSV)"
                            isLoadingText={"Generando informe"}
                            body={<span>
                                ¿Estás seguro de generar el informe?
                            </span>}
                        />
                        {generarEnFormatoPDF &&
                            <>
                                <Dialog
                                    preventFocus
                                    action={() => handleClickGenerarPDF({ sin_concurrencias: false })}
                                    title={"Informe Total"}
                                    variant="primary"
                                    size="md"
                                    btnConfirmText="Generar"
                                    btnVariant="primary"
                                    btnText="Informe Total"
                                    isLoadingText={"Generando informe"}
                                    body={<span>
                                        ¿Estás seguro de generar el informe?
                                    </span>}
                                />

                                <Dialog
                                    preventFocus
                                    action={() => handleClickGenerarPDF({ sin_concurrencias: true })}
                                    title={"Informe sin concurrencias"}
                                    variant="primary"
                                    size="md"
                                    btnConfirmText="Generar"
                                    btnVariant="primary"
                                    btnText="Informe sin concurrencias"
                                    isLoadingText={"Generando informe"}
                                    body={<span>
                                        ¿Estás seguro de generar el informe?
                                    </span>}
                                />
                            </>
                        }
                    </PrivateComponent>
                    <PrivateComponent permisos={PERMISOS_MAP.EXPORTAR_INFORMATIVO}>
                        <Dialog
                            preventFocus
                            action={handleClickGenerarInformativo}
                            title={"Informe para Destinatarios"}
                            variant="primary"
                            size="md"
                            btnConfirmText="Generar"
                            btnVariant="primary"
                            btnText="Informe para Destinatarios"
                            isLoadingText={"Generando informe"}
                            body={<span>
                                ¿Estás seguro de generar el informe para destinatarios?
                            </span>}
                        />
                    </PrivateComponent>
                </Col>
            </Row>}

            {
                loading && <Loader></Loader>
            }
        </ScreenWithFabButtons >
    )
}
