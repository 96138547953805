import React, { useEffect, useReducer, useRef, useState } from 'react'
import { Accordion, Button, Card, Col, Row, Spinner, Table } from 'react-bootstrap'
import { TrEnConcurrenciaCon } from '../../../../incumbencias/details/ternas/TrEnConcurrenciaCon'
import Input from '../../../../ui/Input'

export const IncumbenciaAccordion = ({
    ternasFromIncumbencias,
    arrIncumbencias,
    handleClickCheck,
    withCheckbox,
    selectedChecks = {},
    setSelectedChecks,
    handleLoadDetail,
  }) => {

    const wasAllSelect = () => {
        return Array.isArray(arrIncumbencias) ? arrIncumbencias.every(ctEl => selectedChecks[ctEl.id]) : false
    }

    const handleChangeAll = (e) => {
        handleClickCheck(e, arrIncumbencias); // Actualiza todos los checkboxes
        if (e.target.checked) {
          const allChecked = arrIncumbencias.reduce((acc, ctEl) => {
            acc[ctEl.id] = true;
            return acc;
          }, {});
          setSelectedChecks(prevSelected => ({ ...prevSelected, ...allChecked }));
        } else {
          const allUnchecked = arrIncumbencias.reduce((acc, ctEl) => {
            acc[ctEl.id] = false;
            return acc;
          }, {});
          setSelectedChecks(prevSelected => ({ ...prevSelected, ...allUnchecked }));
        }
      }
      

    const [isLoading, setIsLoading] = useState(false)
    const [showErrorMsg, setShowErrorMsg] = useState(false)

    const handleClickLoadDetail = async () => {
        try {
            if (!arrIncumbencias && !isLoading) {
                setIsLoading(true)
                const params = {
                    terna_id: ternasFromIncumbencias.terna.id,
                    terna2_id: null,
                    terna3_id: null,
                }
                if (ternasFromIncumbencias.terna2) params.terna2_id = ternasFromIncumbencias.terna2.id
                if (ternasFromIncumbencias.terna3) params.terna3_id = ternasFromIncumbencias.terna3.id
                await handleLoadDetail(params)
                setShowErrorMsg(false)
            }
        } catch (err) {
            setShowErrorMsg(true)
        } finally {
            setIsLoading(false)
        }
    }

    console.log({
        ternasFromIncumbencias,
        arrIncumbencias,
    })
    
    return (
        <Accordion className="form-group">
            <Card>
                <Accordion.Toggle as={Card.Header} eventKey="0" onClick={handleClickLoadDetail}>
                    <Row>
                        <Col xs={12} md={8} lg={9} xl={11}>
                            <h5 onClick={() => console.log({ arrIncumbencias })}>{ternasFromIncumbencias.terna.titulo.nombre}</h5>
                            <span className="accordion-header-text">
                                {ternasFromIncumbencias.terna.procedencia.nombre}
                            </span>

                            <span className="accordion-header-text">
                                {ternasFromIncumbencias.terna.resolucion.nombre}
                            </span>
                            {
                                (ternasFromIncumbencias.terna2 || ternasFromIncumbencias.terna2) && <p className="text-info my-3">En concurrencia con:</p>
                            }
                            {
                                ternasFromIncumbencias.terna2 && <>
                                    <h5> {ternasFromIncumbencias.terna2.titulo.nombre}</h5>
                                    <span className="accordion-header-text">
                                        {ternasFromIncumbencias.terna2.procedencia.nombre}
                                    </span>

                                    <span className="accordion-header-text">
                                        {ternasFromIncumbencias.terna2.resolucion.nombre}
                                    </span>
                                </>
                            }
                            {
                                ternasFromIncumbencias.terna3 && <>
                                    <h5 className={ternasFromIncumbencias.terna2 ? "mt-3" : ""}> {ternasFromIncumbencias.terna3.titulo.nombre}</h5>
                                    <span className="accordion-header-text">
                                        {ternasFromIncumbencias.terna3.procedencia.nombre}
                                    </span>

                                    <span className="accordion-header-text">
                                        {ternasFromIncumbencias.terna3.resolucion.nombre}
                                    </span>
                                </>
                            }

                        </Col>
                        <Col sm={1} className="d-flex align-items-center">
                            {withCheckbox && <div onClick={(e) => e.stopPropagation()}>
                                <Input
                                    type="checkbox"
                                    name="id"
                                    // label={"Seleccionar todas"}
                                    id={`$grupoIncumbencias_incumbencia_row_grupo-checkbox`}
                                    checked={wasAllSelect()}
                                    onChange={handleChangeAll}
                                    containerStyle={{ margin: 0, padding: 0 }}
                                    groupStyle={{ margin: 0, padding: 0, height: "22px" }}
                                    classNameLabel="d-flex justify-content-end"
                                    disabled={!arrIncumbencias || arrIncumbencias.length == 0}
                                ></Input>
                            </div>}
                        </Col>
                    </Row>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <Row className="form-group">
                            <Col>
                                {
                                    arrIncumbencias && arrIncumbencias.length > 0 && !isLoading && !showErrorMsg &&
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th /* width="35%" */>Apendice</th>
                                                <th /* width="30%" */>Folio</th>
                                                <th /* width="30%" */>Area</th>
                                                <th /* width="20%" */>Tipo escuela</th>
                                                <th /* width="20%" */>Cargo</th>
                                                <th /* width="20%" */>Asignatura</th>
                                                <th /* width="20%" */>Especialidad</th>
                                                <th /* width="20%" */>Condicion</th>
                                                <th /* width="20%" */>Puntaje</th>
                                                <th /* width="20%" */>Suma OT</th>
                                                {withCheckbox && <th></th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                arrIncumbencias.map(ctIncumbencia =>
                                                    <React.Fragment key={`grupoIncumbencias_incumbencia_row_${ctIncumbencia.id}`}>
                                                        <tr>
                                                            <td>{ctIncumbencia.apendice.id}</td>
                                                            <td>{ctIncumbencia.folio}</td>
                                                            <td>{ctIncumbencia.area.nombre}</td>
                                                            <td>{ctIncumbencia.tipoEscuela.nombre}</td>
                                                            <td>{ctIncumbencia.cargo.nombre}</td>
                                                            <td>{ctIncumbencia.asignatura.nombre}</td>
                                                            <td>{ctIncumbencia.especialidad.nombre}</td>
                                                            <td>{ctIncumbencia.tipoIncumbencia.nombre}</td>
                                                            <td>{ctIncumbencia.puntaje}</td>
                                                            <td >{ctIncumbencia.sumaOT ? "SI" : "NO"}</td>
                                                            {withCheckbox && <td>
                                                                <div key={`grupoIncumbencias_incumbencia_row_${ctIncumbencia.id}`} className="form-checkbox-container">
                                                                    <Input
                                                                        type="checkbox"
                                                                        name="id"
                                                                        id={`grupoIncumbencias_incumbencia_row_${ctIncumbencia.id}-checkbox`}
                                                                        checked={!!selectedChecks[ctIncumbencia.id]}
                                                                        onChange={(e) => handleClickCheck(e, ctIncumbencia)}
                                                                    ></Input>
                                                                </div>
                                                            </td>}
                                                        </tr>
                                                    </React.Fragment>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                }
                                {
                                    isLoading &&
                                    <div className="mt-3 d-flex flex-column justify-content-center align-items-center">
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="md"
                                            role="status"
                                            aria-hidden="true"
                                            variant={"secondary"}
                                        />
                                        <p className="font-weight-bold mb-0" style={{ color: "#414142" }}>
                                            Cargando
                                        </p>
                                    </div>
                                }

                                {
                                    showErrorMsg && !isLoading &&
                                    <>
                                        <p className="mt-3 mb-0 font-weight-bold" style={{ color: "#414142" }}>
                                            No se pudo cargar el detalle.
                                            <Button variant="link" onClick={() => console.log("ASD")}>Reintentar</Button>
                                        </p>
                                    </>
                                }

                            </Col>
                        </Row>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion >
    )
}
