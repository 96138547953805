import React, { useState } from 'react';
import { Accordion, Badge, Card, Col, Row } from 'react-bootstrap';

export const UnificadorSearchRowMock = ({ unificacion }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    // Mock de una terna "principal"
    const mockTerna = {
        id: `mock-${unificacion.id}`,
        terna: {
            titulo: { nombre: `Terna Principal: ${unificacion.terna.titulo.nombre}` },
            procedencia: { nombre: `${unificacion.terna.procedencia.nombre}` },
            resolucion: { nombre: `${unificacion.terna.resolucion.nombre}` },
            estado: { nombre: 'Deshabilitado' },
        },
    };

    // Función que asigna el color según el estado
    const getEstadoColor = (estado) => {
        switch (estado) {
            case 'Aprobado':
                return 'success';  // Verde
            case 'Deshabilitado':
                return 'danger';  // Rojo
            case 'Pendiente':
                return 'warning';  // Amarillo
            default:
                return 'secondary';  // Gris
        }
    };

    const formatData = (procedencia, resolucion, estado) => {
        return `${procedencia}${procedencia && resolucion ? ' | ' : ''}${resolucion}${(procedencia || resolucion) ? ' | ' : ''}`;
    };    

    return (
        <Accordion className="form-group">
            <Card key={unificacion.id}>
                <Accordion.Toggle as={Card.Header} eventKey="0" onClick={handleToggle}>
                    <Row>
                        <Col xs={12} md={8} lg={9} xl={10}>
                            <h5>{"Terna Unificada: " + unificacion.terna.titulo.nombre}</h5>
                            <span className="accordion-header-text">
                                {formatData(unificacion.terna.procedencia.nombre, unificacion.terna.resolucion.nombre, unificacion.terna.estado.nombre)}
                                <Badge variant={getEstadoColor(unificacion.terna.estado.nombre)}>
                                    {unificacion.terna.estado.nombre}
                                </Badge>
                            </span>
                        </Col>
                    </Row>
                </Accordion.Toggle>
                {isOpen && (
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <Row>
                                <Col xs={12} md={8} lg={9} xl={10}>
                                    <h5>{mockTerna.terna.titulo.nombre}</h5>
                                    <span className="accordion-header-text">
                                        {formatData(mockTerna.terna.procedencia.nombre, mockTerna.terna.resolucion.nombre, mockTerna.terna.estado.nombre)}
                                        <Badge variant={getEstadoColor(mockTerna.terna.estado.nombre)}>
                                            {mockTerna.terna.estado.nombre}
                                        </Badge>
                                    </span>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Accordion.Collapse>
                )}
            </Card>
        </Accordion>
    );
};
