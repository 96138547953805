import React, { useEffect, useRef, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import Dialog from '../../generics/dialog'
import { clear, error, info, success } from '../../store/alerts/alertActions'
import { ScreenWithFabButtons } from '../../ui/ScreenWithFabButtons'
import { clonadorConstants, tipoDocumentoClonador } from '../../utils/Constants'
import IncumbenciasService, { clonarIncumbenciasCargo, clonarIncumbenciasTerna } from '../IncumbenciasService'
import { ClonadorCardElements } from './ClonadorCardElements'
import { ClonadorForm } from './ClonadorForm'
import { ClonadorNav } from './ClonadorNav'
import { ClonadorSearchElement } from './ClonadorSearchElement'
import Input from '../../ui/Input'
import { handlerChange, isValidPuntaje } from '../../utils/commons'

const clonarElemento = async (cloneType, original, destino, filtros) => {
  switch (cloneType) {
    case clonadorConstants.TERNAS: {
      const params = {
        ...filtros,
        "terna_origen_id": original[0].id,
        "terna_destino_id": destino[0].id,
      }
      return await clonarIncumbenciasTerna(params);
    }
    case clonadorConstants.CARGOS: {
      let params = { ...filtros, }

      const addParamsMulti = (cargo, path) => {
        params = {
          ...params,
          [`cargo_${path}_id`]: cargo.cargo.id,
          [`asignatura_${path}_id`]: cargo.variantes[0].asignatura.id,
          [`especialidad_${path}_id`]: cargo.variantes[0].especialidad.id,
          [`tipo_escuela_${path}_id`]: cargo.variantes[0].tipoEscuela.id,
          [`area_${path}_id`]: cargo.variantes[0].area.id,
        }
      }

      const addParamsSimple = (cargo, path) => {
        params = {
          ...params,
          [`cargo_${path}_id`]: cargo.cargo.id,
          [`asignatura_${path}_id`]: cargo.asignatura.id,
          [`especialidad_${path}_id`]: cargo.especialidad.id,
        }
      }

      if (original[0].type == "simple") { addParamsSimple(original[0], "origen") } else { addParamsMulti(original[0], "origen") }
      if (destino[0].type == "simple") { addParamsSimple(destino[0], "destino") } else { addParamsMulti(destino[0], "destino") }

      return await clonarIncumbenciasCargo(params);
    }
    default: {
      break;
    }
  }
}

const agregarClonadas = (cloneType, cantidadAgregada, setter) => {
  if (cloneType == clonadorConstants.TERNAS) {
    setter(st => {
      const newSt = [...st]
      newSt[0].cantidadIncumbencias += cantidadAgregada
      return newSt
    })
  }

  if (cloneType == clonadorConstants.CARGOS) {
    setter(st => {
      const newSt = [...st]
      if (newSt[0].type == "simple") {
        newSt[0].cantidadIncumbencias += cantidadAgregada
      } else {
        newSt[0].variantes[0].cantidadIncumbencias += cantidadAgregada
      }
      return newSt
    })
  }
}

export const ClonadorIncumbencias = ({
}) => {
  const [selectDocument, setSelectDocument] = useState();
  const [original, setOriginal] = useState([]);
  const [destino, setDestino] = useState([]);

  const [apendiceSeleccionado, setApendiceSeleccionado] = useState("")
  const { tipoIncumbencias } = useSelector(st => st.data)

  const [inputsForm, setInputsForm] = useState({})
  const dispatch = useDispatch();

  const [validated, setValidated] = useState(false);
  const formRef = useRef();

  const { cloneType } = useParams();

  const isOrigenDestinoCompleted = () => {
    let isCompleted = true;
    switch (cloneType) {
      case clonadorConstants.TERNAS: {
        isCompleted = original.length > 0 && destino.length > 0
        break
      }
      case clonadorConstants.CARGOS: {
        isCompleted =
          original.length > 0 && (original[0].type == "simple" ? true : original[0].variantes.length > 0)
          &&
          destino.length > 0 && (destino[0].type == "simple" ? true : destino[0].variantes.length > 0)
        break
      }
      default: {
        isCompleted = false;
      }
    }
    return isCompleted
  }

  const handleSubmit = async () => {
    if (!formRef.current?.checkValidity() === false && isOrigenDestinoCompleted()) {
      const condicionTerna = !original[0].cantidadIncumbencias || !(original[0].cantidadIncumbencias > 0)
      const condicionCargo = original[0].type == "multi" ?
        !original[0].variantes[0].cantidadIncumbencias || !(original[0].variantes[0].cantidadIncumbencias > 0)
        :
        !original[0].cantidadIncumbencias || !(original[0].cantidadIncumbencias > 0)

      if (clonadorConstants.TERNAS == cloneType ? condicionTerna : condicionCargo) {
        const elementMsg = clonadorConstants.TERNAS == cloneType ? "La terna" : "El cargo"
        dispatch(error(`${elementMsg} original no presenta incumbencias que clonar`))
      } else {
        try {

          const resp = await clonarElemento(cloneType, original, destino, inputsForm);

          const cantidadNoAgregadasYaExistian = resp.data.no_agregadas;
          const cantidadNoAgregadasInvalidas = resp.data.invalidas;
          const cantidadNoAgregadas = cantidadNoAgregadasYaExistian + cantidadNoAgregadasInvalidas
          const cantidadActualizadas = resp.data.actualizadas;
          const cantidadAgregada = resp.data.agregadas;

          if (cantidadAgregada == 0 && cantidadActualizadas == 0 && cantidadNoAgregadas == 0) {
            dispatch(info(`El destino ya contaba con todas las incumbencias de origen.`))
          }

          if (cantidadNoAgregadas > 0) {
            dispatch(info(<>Se agregaron <b>{cantidadAgregada}</b> incumbencias. Se actualizaron <b>{cantidadActualizadas}</b> incumbencias. No se agregaron <b>{cantidadNoAgregadas}</b> incumbencias ({cantidadNoAgregadasYaExistian} ya existian en destino y {cantidadNoAgregadasInvalidas} no son validas para clonar)</>))
          }

          if ((cantidadAgregada > 0 || cantidadActualizadas > 0) && cantidadNoAgregadas == 0) {
            dispatch(success(`Se clonaron ${cantidadAgregada + cantidadActualizadas} incumbencias exitosamente (se crearon ${cantidadAgregada} y se actualizaron ${cantidadActualizadas}).`))
          }

          if (cantidadAgregada > 0) {
            agregarClonadas(cloneType, cantidadAgregada, setDestino)
          }
        } catch (err) {
          dispatch(error(err.response.data.message))
        }
      }
    }
    setValidated(true);
  }

  const invertirTernas = () => {
    setOriginal(destino);
    setDestino(original);

    // setWasModified(true);
  }

  const returnCallBack = () => {
    setSelectDocument("")
  }

  const isValidElementSelection = (newElemento) => {
    if (!newElemento) return "Debe seleccionar al menos un elemento."

    const isRepeated = () => {
      if (selectDocument == tipoDocumentoClonador.ORIGINAL && destino.length > 0 && destino[0].id == newElemento.id) return true
      if (selectDocument == tipoDocumentoClonador.DESTINO && original.length > 0 && original[0].id == newElemento.id) return true
      return false
    }
    if (isRepeated()) return "El elemento original y el de destino no pueden ser el mismo"
  }

  const handleClickSeleccionar = async (newElemento, setIsLoading) => {
    if (selectDocument == tipoDocumentoClonador.ORIGINAL) {
      setOriginal([newElemento])
    }
    if (selectDocument == tipoDocumentoClonador.DESTINO) {
      setDestino([newElemento])
    }
    // setWasModified(true);
    dispatch(clear())
    setSelectDocument("")
  }

  const onClickSeleccionarOriginal = () => {
    setSelectDocument(tipoDocumentoClonador.ORIGINAL)
  }

  const onClickSeleccionarDestino = () => {
    setSelectDocument(tipoDocumentoClonador.DESTINO)
  }

  const handleAgregarVarianteCargo = async (variante, tipoDocumento) => {
    const setter = (tipoDocumento == tipoDocumentoClonador.ORIGINAL) ? setOriginal : setDestino
    const st = (tipoDocumento == tipoDocumentoClonador.ORIGINAL) ? original : destino

    const agregarIncumbenciasDeVariante = async (variante) => {
      const incumbencias = await IncumbenciasService.getIncumbencias({
        asignatura_id: variante[0].asignatura.id,
        especialidad_id: variante[0].especialidad.id,
        cargo_id: st[0].cargo.id,
        tipo_escuela_id: variante[0].tipoEscuela.id,
        area_id: variante[0].area.id
      });

      const varianteSeleccionada = variante[0]

      return [
        {
          ...varianteSeleccionada,
          cantidadIncumbencias: incumbencias.cantidad,
          incumbenciasPrimerPagina: incumbencias.elementos,
          incluir_relacionadas: false,
        }
      ]
    }
    const varianteConIncumbencias = await agregarIncumbenciasDeVariante(variante)
    setter(st => {
      let cargo = st[0];
      cargo.variantes = varianteConIncumbencias
      return [cargo]
    })
  }

  const handleChangeCondicion = (e, componentPuntajeKey) => {
    handlerChange(setInputsForm, inputsForm, null, e)
    const eventPuntaje = { target: { value: null, id: componentPuntajeKey } }
    handlerChange(setInputsForm, inputsForm, null, eventPuntaje)
  };

  const handleChangePuntaje = (evento) => {
    if (evento.target.value && !isValidPuntaje(evento.target.value)) return false
    handlerChange(setInputsForm, inputsForm, null, evento)
  }

  const getSelectCondicionComponent = ({
    componentLabel,
    componentKey,
    componentPuntajeKey,
    withPuntaje,
    checkRequiredKey
  }) => {
    // checkRequiredKey: "condicion_origen",

    return <div className="d-flex">
      <Input
        type="select"
        controlId={componentKey}
        style={{ width: "12rem" }}
        value={inputsForm[componentKey]}
        onChange={(e) => handleChangeCondicion(e, componentPuntajeKey)}
        label={componentLabel}
        elements={tipoIncumbencias}
        required={checkRequiredKey ? !!inputsForm[checkRequiredKey] : false}
        classNameContainer="mr-2"
      ></Input>
      {withPuntaje &&
        tipoIncumbencias?.length > 0 &&
        inputsForm[componentKey] == tipoIncumbencias.find(ctCond => ctCond.descripcion == "OT").id &&
        <>
          <Input
            type="input"
            controlId={componentPuntajeKey}
            label="Puntaje"
            style={{ width: "12rem" }}
            value={inputsForm[componentPuntajeKey] ?? ""}
            onChange={handleChangePuntaje}
            description={"(Del 0 al 3)"}
            required={
              checkRequiredKey ?
                !!inputsForm[checkRequiredKey] || !!inputsForm[componentKey]
                :
                false
            }
            classNameContainer="mr-2"
          ></Input>
          {/* <Input
                        type="checkbox"
                        controlId="sumaOT"
                        name="sumaOT"
                        label="Solo aplica para Otros titulos"
                        value="0"
                        checked={ctElemento.inputs?.sumaOT}
                        onChange={(event) => handleChangeCheckBox(event, (event) => handleChangeRowTernaInputs(event, index))}
                    ></Input> */}
        </>
      }

    </div>
  }

  const resetForm = () => {
    setSelectDocument("")
    setOriginal([])
    setDestino([])
    setValidated(false)
  }

  useEffect(() => {
    resetForm();
  }, [cloneType])

  return (
    <>
      {!selectDocument &&
        <ScreenWithFabButtons
          hidePlusButton={true}
          hideSearchButton={true}
          containerFluid={true}
        >
          <Row className="mx-auto">
            <Col className="col-fluid">
              <div className="container">
                <Col>
                  <h1>Clonador de incumbencias</h1>
                  <h3>Clonar por</h3>
                  <ClonadorNav />
                  <hr></hr>
                </Col>
              </div>
              <Form
                ref={formRef}
                noValidate
                validated={validated}
                onSubmit={e => e.preventDefault()}
              >
                <div className="container">
                  <ClonadorForm
                    inputsForm={inputsForm}
                    setInputsForm={setInputsForm}
                    validated={validated}
                    apendiceSeleccionado={apendiceSeleccionado}
                    setApendiceSeleccionado={setApendiceSeleccionado}
                  />
                </div>
                <ClonadorCardElements
                  original={original}
                  destino={destino}
                  onClickSeleccionarOriginal={onClickSeleccionarOriginal}
                  onClickSeleccionarDestino={onClickSeleccionarDestino}
                  handleAgregarVarianteCargo={handleAgregarVarianteCargo}
                  invertirTernas={invertirTernas}
                  validated={validated}
                  getSelectCondicionComponent={getSelectCondicionComponent}
                />
              </Form>
            </Col>
          </Row>

          {
            // wasModified && (
            <Row className="container mx-auto mt-4">
              <Col>
                <Dialog
                  action={handleSubmit}
                  title={"Clonar Incumbencias"}
                  variant="primary"
                  size="md"
                  btnConfirmText="Clonar"
                  btnVariant="secondary"
                  btnText="Clonar Incumbencias"
                  isLoadingText={"Clonando"}
                  body={<span>¿Estás seguro de clonar las incumbencias?</span>}
                />
              </Col>
            </Row>
            // )
          }

        </ScreenWithFabButtons>
      }

      {selectDocument &&
        <ClonadorSearchElement
          selectDocument={selectDocument}
          handleClickSeleccionar={handleClickSeleccionar}
          returnCallBack={returnCallBack}
          isValidElementSelection={isValidElementSelection}
        />
      }
    </>
  )
}

