import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '../generics/IconButton';
import { ArrowRotate } from '../images/svg-jsx/ArrowRotate';
import { Cruz } from '../images/svg-jsx/Cruz';
import { OPCIONES_DESCARGAS, cancelarDescargas, reiniciarDescargas } from '../store/descargas/descargasActions.';
import { CustomTooltip } from '../ui/CustomTooltip';
import { tooltipsText } from '../utils/Constants';

import { downloadCSVInformeCruzado, downloadCSVInformeIncumbByTernaPrimeraVista, downloadCSVInformeIncumbByTernaSegundaVista, downloadCSVInformeTernaTitulos, downloadCSVInformeTopologico, downloadCSVTernasByAreaCargoPrimeraVista, downloadCSVTernasByAreaCargoSegundaVista, downloadPDFInformeCruzado, downloadPDFInformeCruzadoPorCalidad, downloadPDFInformeEquivalencias, downloadPDFInformeIncumbByTernaPrimeraVista, downloadPDFInformeIncumbByTernaSegundaVista, downloadPDFInformeIncumbByTernaSegundaVistaInformativo, downloadPDFInformeIncumbByTernaSegundaVistaSinConcurrencias, downloadPDFInformeTernaProcedencias, downloadPDFInformeTernaResoluciones, downloadPDFInformeTernaTitulos, downloadPDFInformeTopologico, downloadPDFTernasByAreaCargo, downloadPDFTernasByAreaCargoPrimeraVista, downloadPDFTernasByAreaCargoPrimeraVistaInformativo, downloadPDFTernasByAreaCargoSegundaVista, downloadTESTEOCancelar, downloadTESTEOCompletar } from './DescargasService';

export const MAPA_DESCARGAS = {
    [OPCIONES_DESCARGAS.PDF.INFORME_INCUMBENCIAS_BY_TERNA_PRIMERA_VISTA]: downloadPDFInformeIncumbByTernaPrimeraVista,
    [OPCIONES_DESCARGAS.CSV.INFORME_INCUMBENCIAS_BY_TERNA_PRIMERA_VISTA]: downloadCSVInformeIncumbByTernaPrimeraVista,
    [OPCIONES_DESCARGAS.PDF.INFORME_INCUMBENCIAS_BY_TERNA_SEGUNDA_VISTA]: downloadPDFInformeIncumbByTernaSegundaVista,
    [OPCIONES_DESCARGAS.PDF.INFORME_INCUMBENCIAS_BY_TERNA_SEGUNDA_VISTA_INFORMATIVO]: downloadPDFInformeIncumbByTernaSegundaVistaInformativo,
    [OPCIONES_DESCARGAS.PDF.INFORME_INCUMBENCIAS_BY_TERNA_SEGUNDA_VISTA_SIN_CONCURRENCIAS]: downloadPDFInformeIncumbByTernaSegundaVistaSinConcurrencias,
    [OPCIONES_DESCARGAS.CSV.INFORME_INCUMBENCIAS_BY_TERNA_SEGUNDA_VISTA]: downloadCSVInformeIncumbByTernaSegundaVista,
    [OPCIONES_DESCARGAS.PDF.INFORME_TOPOLOGICO]: downloadPDFInformeTopologico,
    [OPCIONES_DESCARGAS.CSV.INFORME_TOPOLOGICO]: downloadCSVInformeTopologico,
    [OPCIONES_DESCARGAS.PDF.INFORME_BY_AREA_CARGO_PRIMERA_VISTA]: downloadPDFTernasByAreaCargoPrimeraVista,
    [OPCIONES_DESCARGAS.CSV.INFORME_BY_AREA_CARGO_PRIMERA_VISTA]: downloadCSVTernasByAreaCargoPrimeraVista,
    [OPCIONES_DESCARGAS.PDF.INFORME_BY_AREA_CARGO_SEGUNDA_VISTA_INFORMATIVO]: downloadPDFTernasByAreaCargoSegundaVista,
    [OPCIONES_DESCARGAS.PDF.INFORME_BY_AREA_CARGO_SEGUNDA_VISTA]: downloadPDFTernasByAreaCargoSegundaVista,
    [OPCIONES_DESCARGAS.CSV.INFORME_BY_AREA_CARGO_SEGUNDA_VISTA]: downloadCSVTernasByAreaCargoSegundaVista,
    [OPCIONES_DESCARGAS.PDF.INFORME_CRUZADO]: downloadPDFInformeCruzado,
    [OPCIONES_DESCARGAS.CSV.INFORME_CRUZADO]: downloadCSVInformeCruzado,
    [OPCIONES_DESCARGAS.PDF.INFORME_CRUZADO_POR_AREA_VER_CALIDAD]: downloadPDFInformeCruzadoPorCalidad,
    [OPCIONES_DESCARGAS.CSV.INFORME_TERNA_TITULOS]: downloadCSVInformeTernaTitulos,
    [OPCIONES_DESCARGAS.PDF.INFORME_TERNA_TITULOS]: downloadPDFInformeTernaTitulos,
    [OPCIONES_DESCARGAS.PDF.INFORME_TERNA_RESOLUCIONES]: downloadPDFInformeTernaResoluciones,
    [OPCIONES_DESCARGAS.PDF.INFORME_TERNA_PROCEDENCIAS]: downloadPDFInformeTernaProcedencias,
    [OPCIONES_DESCARGAS.PDF.INFORME_EQUIVALENCIAS]: downloadPDFInformeEquivalencias,
    [OPCIONES_DESCARGAS.TESTEO.CANCELAR]: downloadTESTEOCancelar,
    [OPCIONES_DESCARGAS.TESTEO.COMPLETAR]: downloadTESTEOCompletar,
}

export const ESTADO_DESCARGA = {
    ACTIVA: "ACTIVA",
    CANCELADA: "CANCELADA",
    COMPLETADA: "COMPLETADA",
    ELIMINADA: "ELIMINADA",
}

const DescargaRow = ({ descarga, showReiniciarBtn, showEliminarBtn }) => {
    const dispatch = useDispatch();

    const handleCancelar = () => {
        if (descarga?.abortSource) {
            return descarga?.abortSource.cancel()
        }
        dispatch(cancelarDescargas([descarga]))
    }

    return <div className="descargas__padding_content descargas_row" style={{ display: "flex", flexDirection: "column", paddingTop: "10px", paddingBottom: "10px" }}>
        <div style={{ display: "flex" }}>
            <div style={{ flexGrow: "1" }}>
                <b>#{descarga.id}</b> - {descarga.title}
            </div>
            <div className="descargas__icon-container">
                {/* 
                <CustomTooltip text={descarga.showDescripcion ? tooltipsText.VER_MENOS : tooltipsText.VER_MAS}>
                    <IconButton
                        onClick={() => dispatch(toggleShowDescripcion([descarga]))}
                    >
                        {descarga.showDescripcion ?
                            <FontAwesomeIcon icon={faChevronUp} size="s" />
                            :
                            <FontAwesomeIcon icon={faChevronDown} size="s" />
                        }
                    </IconButton>
                </CustomTooltip> 
                */}

                {showReiniciarBtn &&
                    <CustomTooltip text={tooltipsText.REINICIAR}>
                        <IconButton onClick={() => dispatch(reiniciarDescargas([descarga]))}>
                            <ArrowRotate></ArrowRotate>
                        </IconButton>
                    </CustomTooltip>
                }

                <CustomTooltip text={showEliminarBtn ? tooltipsText.ELIMINAR : tooltipsText.CANCELAR}>
                    <IconButton onClick={handleCancelar}>
                        <Cruz></Cruz>
                    </IconButton>
                </CustomTooltip>
            </div>
        </div>
        {/* 
        {
            descarga.showDescripcion &&
            <div>
                {Object.keys(descarga.filtros).map((ctFiltro, index) => <div><b>{ctFiltro}:</b> {descarga.filtros[ctFiltro]} </div>)}
            </div>
        } 
        */}
    </div>
}

export const DescargasPanel = () => {
    const [sectionPanel, setSectionPanel] = useState(ESTADO_DESCARGA.ACTIVA);

    const {
        arrActivas,
        arrCanceladas,
        arrCompletadas,
    } = useSelector(st => st.descargas)

    const changeSection = (section) => {
        setSectionPanel(section)
    }

    const tituloActivas = "Descargas activas" + (arrActivas.length > 0 ? ` (${arrActivas.length})` : "");
    const tituloCanceladas = "Canceladas" + (arrCanceladas.length > 0 ? ` (${arrCanceladas.length})` : "");
    const tituloCompletadas = "Completado" + (arrCompletadas.length > 0 ? ` (${arrCompletadas.length})` : "");

    return (
        <div className="descargas__container">
            <div className="descargas__btn-container">
                <button style={{ flexGrow: 2 }} className={`descargas__section-btn ${sectionPanel == ESTADO_DESCARGA.ACTIVA ? "active" : ""}`} onClick={() => changeSection(ESTADO_DESCARGA.ACTIVA)}>
                    {tituloActivas}
                </button>
                <button style={{ flexGrow: 1 }} className={`descargas__section-btn ${sectionPanel == ESTADO_DESCARGA.CANCELADA ? "active" : ""}`} onClick={() => changeSection(ESTADO_DESCARGA.CANCELADA)} >
                    {tituloCanceladas}
                </button>
                <button style={{ flexGrow: 1 }} className={`descargas__section-btn ${sectionPanel == ESTADO_DESCARGA.COMPLETADA ? "active" : ""}`} onClick={() => changeSection(ESTADO_DESCARGA.COMPLETADA)}>
                    {tituloCompletadas}
                </button>
            </div>

            {
                sectionPanel == ESTADO_DESCARGA.ACTIVA &&
                <div className="descargas__content">
                    {
                        arrActivas.length > 0 &&
                        arrActivas.map(ctDescarga => <React.Fragment key={`DescargaActiva${ctDescarga.id}`}><DescargaRow descarga={ctDescarga} /></React.Fragment>)
                    }
                    {arrActivas.length == 0 && <p className="descargas__padding_content" style={{ margin: 0 }}>No hay descargas activas</p>}
                </div>
            }
            {
                sectionPanel == ESTADO_DESCARGA.COMPLETADA &&
                <div className="descargas__content">
                    {
                        arrCompletadas.length > 0 &&
                        arrCompletadas.map(ctDescarga =>
                            <React.Fragment key={`DescargaCompletada${ctDescarga.id}`}><DescargaRow descarga={ctDescarga} showEliminarBtn /></React.Fragment>
                        )
                    }
                    {arrCompletadas.length == 0 && <p className="descargas__padding_content" style={{ margin: 0 }}>No hay descargas completadas</p>}
                </div>
            }
            {
                sectionPanel == ESTADO_DESCARGA.CANCELADA &&
                <div className="descargas__content">
                    {
                        arrCanceladas.length > 0 &&
                        arrCanceladas.map(ctDescarga => <React.Fragment key={`DescargaCancelada${ctDescarga.id}`}><DescargaRow descarga={ctDescarga} showReiniciarBtn showEliminarBtn /></React.Fragment>)
                    }
                    {arrCanceladas.length == 0 && <p className="descargas__padding_content" style={{ margin: 0 }}>No hay descargas canceladas</p>}
                </div>
            }
        </div >

    )
}
