import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { UnificadorSearchRowMock } from './UnificadorSearchRowMock';
import Paginacion from '../../../generics/Paginacion';

export const UnificadorTableMock = ({ elementos, cantidad, page }) => {
    console.log("Elementos recibidos:", elementos);

    const renderTableRows = () => {
        if (!Array.isArray(elementos) || elementos.length === 0) {
            return <p>No hay elementos disponibles.</p>;
        }

        return elementos.map((elemento) => (
            <UnificadorSearchRowMock key={elemento.id} unificacion={elemento} />
        ));
    };

    return (
        <>
            <Row className="form-group">
                <Col>{renderTableRows()}</Col>
            </Row>
            <Paginacion
                totalItems={cantidad}
                currentPage={page}
                onChangePage={(newPage) => console.log(`Página cambiada a: ${newPage}`)}
            />
        </>
    );
};
